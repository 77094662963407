const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  button: {
    margin: theme.spacing.unit,
    marginLeft: 50,
    marginTop: 20,
  },
  featureCard: {
    marginRight: theme.spacing.unit * 2,
  },
  welcomeText: {
    marginBottom: 30,
    marginTop: 20,
  },
  fullHeightDialog: {
    margin: 0,
    maxHeight: "100%",
  },
  paymentPaperDialog: {
    display: "flex",
    alignItems: "center",
  },
  dialogContent: {
    padding: "0px !important",
    height: "100%",
    width: "100%",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
    border: "1.5px solid #fff",
    paddingLeft: "7px",
    color: "#fff",
    borderRadius: "5px",
    height: "32px",
    width: "95px",
    marginTop: 0,
  },
});

export default styles;
