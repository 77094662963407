import { combineReducers } from 'redux';
import remote from './remote'
import local from './local'
import loading from './loading'
const rootReducer = combineReducers({
  remote,
  local,
  loading,
});

export default rootReducer;
