import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import styles from './style'
import classNames from "classnames";
import Button from '@material-ui/core/Button';

class CustomButton extends React.Component{

  render(){
    const {classes, children,className, round, shadows, redBackgroundColor, fullWidth, fixedBottom, circular, ...rest} = this.props;
    console.log('buttonstyle', this.props)
    const btnClasses = classNames({
      [classes.round] : round,
      [className]: className,
      [classes.shadows]: !shadows,
      [classes.redBackgroundColor]: redBackgroundColor,
      [classes.fullWidth]: fullWidth,
      [classes.fixedBottom]: fixedBottom,
      [classes.circular]: circular,
    })
    return(
      <Button {...rest} className={btnClasses}>
        {children}
      </Button>
    )
  }
}

const styledComponent = withRoot(withStyles(styles)(CustomButton))
export {styledComponent as CustomButton}
