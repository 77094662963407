import React, { useState, useEffect } from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Translate } from "../../helpers";
import { enLanguage, frLanguage, arLanguage, zuLanguage } from "../../langData";

export default function Language({appSkin}) {
  const languageCode = localStorage.getItem("ukuLangCode");
  let defaultLang;
  if(languageCode==="en"){
    defaultLang="English"
  }
  else if(languageCode==="ar"){
    defaultLang="Arabic"
  }
  else if(languageCode==="zu"){
    defaultLang="Zulu"
  }
  else{
    defaultLang="French"
  }
  // const [value, setValue] = useState(
  //   languageCode === "en" ? "English" : "French"
  // );
  const [value, setValue] = React.useState(defaultLang);
  // const [appSkin,setAppSkin] = useState(null)

  //   useEffect(()=>{
  //     const skin = localStorage.getItem("skin")
  //     console.log("skin",skin)
  //     let skinTheme = null
  //     if(skin){
  //       console.log("skin-if",skin)
  //       skinTheme = JSON.parse(skin)
  //     console.log("skinTheme-if", skinTheme )
  //     setAppSkin(skinTheme)
  //     }
  //     console.log("skinTheme", skinTheme )
  //   },[])

    const handleChange = (event) => {
      console.log("event",event.target.value)
      setValue(event.target.value);
      console.log("event2",event)
      console.log("state value",value)
        // console.log("event.target.value", event.target.value);
  
        if (confirm(`${Translate("SureChangeLanguage")}?`)) {
          let langCode;
          let lang;
          console.log("event3",event)
      console.log("state value 2",value)
          if(event.target.value==="English"){
            langCode="en"
            lang=enLanguage
          }
          else if(event.target.value==="Arabic"){
            langCode="ar"
            lang=arLanguage
          }
          else if(event.target.value==="Zulu"){
            langCode="zu"
            lang=zuLanguage
          }
          else {
            langCode="fr"
            lang=frLanguage
          }
          localStorage.setItem("ukuLangCode", langCode);
          localStorage.setItem(
            "ukuLang", JSON.stringify(lang)
          );
          window.location.reload();
          // const user = localStorage.getItem("user");
          // let userObj = user ? JSON.parse(user) : null;
          // this.props.logout(userObj);
        }
    };

  console.log("appSkin",appSkin)
  return (
    <div style={{ margin: "20px" }}>
      {/* <FormControl component="fieldset">
        <FormLabel component="legend">{Translate("Language")}</FormLabel>
        <RadioGroup
          aria-label="language"
          name="language"
          value={value}
          onChange={handleChange}
        >
          <FormControlLabel
            value="English"
            control={<Radio color="primary" />}
            label="English"
          />
          <FormControlLabel
            value="French"
            control={<Radio color="primary" />}
            label="French"
          />
        </RadioGroup>
      </FormControl> */}
      <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">{Translate("Language")}</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        {appSkin && appSkin.supportedLanguages.map(lang=><FormControlLabel value={lang.languageName} control={<Radio color="primary" />} label={lang.languageName} />)}
      </RadioGroup>
    </FormControl>
    </div>
  );
}
