import React from 'react';
import {Link} from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import styles from './style'
import withRoot from '../../withRoot';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

class GenericCard extends React.Component {
  constructor(props){
    super(props);
  }

  render(){
    const {classes, header, description, actionText, actionLink, className} = this.props;
    const cardClass= classNames([classes.genericCard, className])
    return(
        <Card className={cardClass}>
          <CardHeader title={header} />
          <CardContent>
            <Typography component="p">
              {description}
            </Typography>
          </CardContent>
          <CardActions className={classes.actions} disableActionSpacing>
            <Link to={actionLink}>
              <Button size="small">{actionText}</Button>
            </Link>
          </CardActions>
        </Card>
    )
  }
}

const styledComponent = withRoot(withStyles(styles)(GenericCard))
export {styledComponent as GenericCard}
