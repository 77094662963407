export const enLanguage = {
  AboutMe: "About Me",
  Submit: "Submit",
  KeepItClean:
    "Keep it clean! No phone, messenger details, web links, abusive or explicit content please",
  NotAbleToLogIn: "We are not able to log you in",
  WriteUsToResolveQuery:
    "Please write to us in the message box below resolve your query.",
  YouMatchedWith: "You matched with",
  Connecting: "Connecting",
  SaySomethingWitty: "Say something witty",
  Community: "Community",
  Search: "Search",
  CrushReceived: "Crush Received",
  CrushesReceivedLikeBack: "Crushes received, like back to chat",
  ThatAllForNow: "That's all for now",
  HaveMoreGreatPeople: "We will have more great people for you in just a bit",
  NoMoreCards: "No more cards",
  Me: "Me",
  Discover: "Discover",
  Matchbox: "Matchbox",
  Hi: "Hi",
  WhatIsYourDOB: "What's your  Date of Birth",
  Next: "NEXT",
  NoAccessUnder18:
    "No access for those under 18 years. You can try again on your 18th birthday.",
  FirstImpressionMakeItCount:
    "Remember, your profile is the first impression you make. Make it count.",
  TeamWoo: "Team Woo",
  Incompatible: "Incompatible",
  PleaseReviewOurTips:
    "Please review our tips to make sure your pics are approved & get liked",
  PhotoTips: "Photo tips",
  EditProfile: "Edit Profile",
  EmailIsNotValid: "Email is not valid",
  PhoneNumberNotValid: "Phone number not valid",
  FeedbackTooShort: "Feedback too short",
  PleaseProvideAllDetails: "Please provide all the details",
  ThankYouForFeedback: "Thank you for your feedback",
  WeWillGetInTouch:
    "We will get in touch with you soon to understand more in detail",
  ContactUsDirectlyAt: "You can contact us directly at",
  UseFormBelow: "or use the form below",
  YourEmail: "Your Email",
  PleaseEnterEmail: "Please enter your email",
  YourPhoneNumber: "Your Phone Number",
  PleaseEnterPhoneNumber: "Please enter your phone number",
  YourFeedback: "Your Feedback",
  ShareYourFeedback: "Share your feedback",
  SUBMIT: "SUBMIT",
  MyGender: "My Gender",
  IAm: "I'm",
  AMan: "a Man",
  AWoman: "a Woman",
  ShowToRelevantprofiles: "We need this to show you to relevant profiles",
  SelectYourGender: "Select your gender",
  ExploreSingles: "EXPLORE SINGLES",
  AlreadyHaveProfile: "I already have a profile on Ukuthanda",
  ByLoggingIn: "By logging in, you agree to our",
  Terms: "Terms",
  And: "and",
  PrivacyPolicy: "Privacy Policy",
  WOOHOO: "WOOHOO",
  LikesYouToo: "likes you too",
  StartChatting: "START CHATTING",
  KeepSwiping: "KEEP SWIPING",
  WhatIsYourCurrentLocation: "What is your current location",
  ShowYouProfilesAroundYou: "We'll use this to show you profiles around you",
  NewMatch: "New Match",
  UnlockWithWOOPlus: "UNLOCK WITH WOO PLUS",
  DiscoverPeople: "Discover People",
  SeeWhoLikedYou: "See who liked you and like back to connect",
  LikesVisits: "Likes and Visits",
  YourFutureLikesVisits: "Your future likes and visits will show here",
  LikedProfiles: "Liked Profiles",
  ProfilesYouLike: "Profiles you like will appear here",
  SkippedProfiles: "Skipped Profiles",
  SkippedProfilesWillShowHere: "Skipped profiles will show here",
  CrushReceived: "Crush Received",
  FutureCrushWillShowHere: "Your future crush will show here",
  YouHaveNoMatchesYet: "You have no matches yet",
  InTheMeantime: "In the meantime",
  GetDiscoveredByMorePeople: "Get discovered by more people",
  ViewProfile: "View Profile",
  Purchases: "Purchases",
  MyPurchases: "My Purchases",
  Store: "Store",
  Plus: "Plus",
  MyNameIs: "My Name is",
  FullName: "Full Name",
  Mandatory: "mandatory",
  FullNameShouldNotBeBlank: "Full name should not be blank",
  TheCharacter: "The character",
  IsNotAllowed: "is not allowed",
  CareAboutPrivacy:
    "We care about your privacy and only show initials from Women and full Name for Men.",
  AllOutOfLikes: "All out of likes",
  GetMoreLikesTomorrow: "Get more likes tomorrow",
  Or: "or",
  GetUnlimitedLikes: "Get Unlimited Likes",
  PhotoTips: "Photo tips",
  PurposeOfPhotoGuidelines:
    "The purpose of photo guidelines is to make you look good and for other members to feel comfortable in contacting you.",
  PhotosOfYourself:
    "Photos of yourself - sharp, clear selfies or portraits, are the best",
  SoloSelfie: "Solo/selfie pics should show you - not someone else",
  ConsideredIncompatible:
    "If any photo is considered incompatible after Woo’s technical and manual screening, it will be removed from your profile.",
  AvoidGroupPhotos: "Avoid group photos - hard to see who you are",
  DontUploadCouplesPhotos:
    "Don’t upload couples photos. It makes you look like you are already in a relationship.",
  DontUploadPhotosWithChildren:
    "Don’t upload photos with children - we are all adults here",
  GotIt: "GOT IT",
  MyLocation: "My Location",
  DistanceFromMe: "Distance from me",
  Miles: "miles",
  AgeRange: "Age range",
  GenderPreferences: "Gender preferences",
  Notifications: "Notifications",
  MatchAlert: "Match Alert",
  ChatMessage: "Chat Message",
  CrushReceived: "Crush Received",
  AnswersReceived: "Answers Received",
  SoundAlerts: "Sound alerts",
  HideMeMutualFriends: "Hide me in mutual friends ",
  CannotSeeMutualFriendsEither: "When on, you cannot see mutual friends either",
  WantSignOut: "Are you sure, want to Sign Out",
  Settings: "Settings",
  SignOut: "Sign Out",
  TryAnotherPicture: "Try Another Picture",
  AddNicePicture: "Add a nice picture of you",
  UploadAnotherPhoto: "UPLOAD ANOTHER PHOTO",
  WeCouldNotSeeYouClearly: "We coul'd not see you clearly in the Photo",
  PleaseAddAnother: "Please Add another",
  Subscriptions: "Subscriptions",
  YourSubscriptionIs: "Your subscription is",
  Active: "active",
  Cancelled: "cancelled",
  NextDueDate: "Next Due Date",
  ExpiryDate: "Expiry Date",
  Unsubscribe: "Unsubscribe",
  AlreadyUnsubscribed: "Already Unsubscribed",
  UploadPhoto: "UPLOAD PHOTO",
  Language: "Language",
  SureChangeLanguage: "Are you sure to change language",
  Feedback: "Feedback",
  JoinUkuthanda: "Join Ukuthanda",
  MakeMeaningfulConnections: "Make Meaningful Connections",
  ChooseInterests: "Choose Interests",
  Save: "Save",
  AddTagsThatDescribe:
    "Add tags that describe you best to meet like-minded people",
  AddAtleast: "Add at least 2 tags",
  IntroduceYourself:
    "Introduce youself with your story. Make it genuine and interesting. Keep it clean! no phone, messenger details, web links, abusive or explicit content please",
  Edit: "Edit",
  // WriteAboutYourself: "Write about yourself",
  MyStory: "My Story",
  MyPersonalTags: "My Personal Tags",
  Unmatch: "Unmatch",
  BlocknReport: "Block and Report",
  AreYouSureToUnmatch: "Are you sure? Tell us why",
  InapMesg: "Inappropriate Messages",
  InapPhotos: "Inappropriate Photos",
  FeelLikeSpam: "Feel like spam",
  Other: "Other",
  NoReason: "No reason",
  HelpUsUnderstand: "Help Us Understand the problem",
  ReportAProfile:
    "When you report a profile, you help us keep the community clean. Tell us why this person should not be on Man ak yow. Don't worry, they don't find out",
  InproperConduct: "Inproper Conduct",
  AbusiveLang: "Abusive Language",
  SendSpamMsg: "Sends spam messages",
  MarriedRel: "Married/In a relationship",
  Misrepresented: "Misrepresented Info",
  Cancel: "Cancel",
  ReportUser: "Report User",
  From18YearsOld: "From 18 years old",
}

export const frLanguage = {
  AboutMe: "À propos de moi",
  Submit: "Soumettre",
  KeepItClean:
    "Soyez courtois! Merci de ne pas mettre de message ou lien web au contenu abusif ou explicite",
  NotAbleToLogIn: "Nous ne sommes pas en mesure de vous connecter",
  WriteUsToResolveQuery:
    "Veuillez nous écrire dans la boîte de message ci-dessous pour résoudre votre requête.",
  YouMatchedWith: "Vous avez matché avec",
  Connecting: "Connexion",
  SaySomethingWitty: "Dites quelque chose d'amusant",
  Community: "Communauté",
  Search: "Chercher",
  CrushReceived: "Crush reçu",
  CrushesReceivedLikeBack: "Crushes reçus comme retour au chat",
  ThatAllForNow: "C'est tout pour l'instant",
  HaveMoreGreatPeople:
    "Nous aurons d'autres personnes formidables pour vous dans quelques instants",
  NoMoreCards: "Plus de cartes",
  Me: "Moi",
  Discover: "Découvrir",
  Matchbox: "Discussions",
  Hi: "Salut",
  WhatIsYourDOB: "Quelle est votre date de naissance ?",
  Next: "Suivant",
  NoAccessUnder18:
    "Pas d'accès pour les moins de 18 ans. Vous pouvez réessayer le jour de votre 18e anniversaire.",
  FirstImpressionMakeItCount:
    "N'oubliez pas que votre profil est la première impression que vous faites. Faites en sorte qu'elle compte.",
  TeamWoo: "équipe Man ak Yow",
  Incompatible: "Incompatible",
  PleaseReviewOurTips:
    "Veuillez consulter nos conseils pour vous assurer que vos photos sont approuvées et aimées",
  PhotoTips: "Conseils photo",
  EditProfile: "Modifier le profil",
  EmailIsNotValid: "L'e-mail n'est pas valide",
  PhoneNumberNotValid: "Numéro de téléphone non valide",
  FeedbackTooShort: "Commentaires trop courts",
  PleaseProvideAllDetails: "Veuillez fournir tous les détails",
  ThankYouForFeedback: "Merci pour vos commentaires",
  WeWillGetInTouch:
    "Nous vous contacterons bientôt pour comprendre plus en détail",
  ContactUsDirectlyAt: "Vous pouvez nous contacter directement à",
  UseFormBelow: "ou utilisez le formulaire ci-dessous",
  YourEmail: "Votre e-mail",
  PleaseEnterEmail: "Veuillez entrer votre email",
  YourPhoneNumber: "Votre numéro de téléphone",
  PleaseEnterPhoneNumber: "Veuillez saisir votre numéro de téléphone",
  YourFeedback: "Vos Feedbacks",
  ShareYourFeedback: "Partagez vos commentaires",
  SUBMIT: "SOUMETTRE",
  MyGender: "Je Suis",
  IAm: "I'm",
  AMan: "un homme",
  AWoman: "une femme",
  ShowToRelevantprofiles:
    "Nous en avons besoin pour vous montrer les profils pertinents",
  SelectYourGender: "Sélectionnez votre sexe",
  ExploreSingles: "EXPLOREZ LES CÉLIBATAIRES",
  AlreadyHaveProfile: "J'ai déjà un profil sur Ukuthanda",
  ByLoggingIn: "En vous connectant, vous acceptez nos",
  Terms: "Conditions",
  And: "Et",
  PrivacyPolicy: "Politique de confidentialité",
  WOOHOO: "WOOHOO",
  LikesYouToo: "t'aime aussi",
  StartChatting: "COMMENCER À CHATTER",
  KeepSwiping: "Défiler",
  WhatIsYourCurrentLocation: "Quelle est votre position actuelle ?",
  ShowYouProfilesAroundYou:
    "Nous allons l'utiliser pour vous montrer des profils autour de vous",
  NewMatch: "Nouveau match",
  UnlockWithWOOPlus: "DÉVERROUILLER AVEC WOO PLUS",
  DiscoverPeople: "DÉCOUVRIR LES GENS",
  SeeWhoLikedYou: "Voir qui vous a aimé et qui vous a plu pour vous connecter",
  LikesVisits: "J'aime & Visites",
  YourFutureLikesVisits: "Vos futurs likes et visites s'afficheront ici",
  LikedProfiles: "Profils aimés",
  ProfilesYouLike: "Les profils que vous aimez apparaîtront ici",
  SkippedProfiles: "Profils ignorés",
  SkippedProfilesWillShowHere: "Les profils ignorés s'afficheront ici",
  CrushReceived: "Crush reçu",
  FutureCrushWillShowHere: "Votre futur coup de cœur s'affichera ici",
  YouHaveNoMatchesYet: "Vous n'avez pas encore de correspondances",
  InTheMeantime: "En attendant",
  GetDiscoveredByMorePeople: "Faites-vous découvrir par plus de personnes",
  ViewProfile: "Afficher le profil",
  Purchases: "Achats",
  MyPurchases: "Mes Achats",
  Store: "Magasin",
  Plus: "Plus",
  MyNameIs: "Mon nom est",
  FullName: "Nom complet",
  Mandatory: "obligatoire",
  FullNameShouldNotBeBlank: "Le nom complet ne doit pas être vide",
  TheCharacter: "Le personnage",
  IsNotAllowed: "n'est pas autorisé",
  CareAboutPrivacy:
    "Nous nous soucions de votre vie privée et ne montrons que les initiales des femmes et le nom complet des hommes.",
  AllOutOfLikes: "Tous sans likes",
  GetMoreLikesTomorrow: "Obtenez plus de likes demain",
  Or: "ou",
  GetUnlimitedLikes: "Obtenez des likes illimités",
  PhotoTips: "Conseils photo",
  PurposeOfPhotoGuidelines:
    "Le but des directives photo est de vous faire bien paraître et de permettre aux autres membres de se sentir à l'aise pour vous contacter.",
  PhotosOfYourself:
    "Des photos de vous-même - les selfies ou les portraits clairs et nets sont les meilleurs",
  SoloSelfie:
    "Les photos en solo/selfie doivent vous montrer - pas quelqu'un d'autre",
  ConsideredIncompatible:
    "Si une photo est considérée comme incompatible après l'examen technique et manuel de Woo, elle sera supprimée de votre profil.",
  AvoidGroupPhotos:
    "Évitez les photos de groupe - difficile de voir qui vous êtes",
  DontUploadCouplesPhotos:
    "Ne téléchargez pas de photos de couples. Cela vous donne l'impression que vous êtes déjà en couple.",
  DontUploadPhotosWithChildren:
    "Ne téléchargez pas de photos avec des enfants - nous sommes tous des adultes ici",
  GotIt: "COMPRIS",
  MyLocation: "Ma position",
  DistanceFromMe: "Distance de moi",
  Miles: "miles",
  AgeRange: "Champ d'âge",
  GenderPreferences: "Préférences de genre",
  Notifications: "Notifications",
  MatchAlert: "Alerte de match",
  ChatMessage: "Message de chat",
  CrushReceived: "Crush reçu",
  AnswersReceived: "Réponses reçues",
  SoundAlerts: "Alertes sonores",
  HideMeMutualFriends: "Cache-moi dans des amis communs ",
  CannotSeeMutualFriendsEither:
    "Lorsque activé, vous ne pouvez pas non plus voir les amis communs",
  WantSignOut: "Voulez-vous vraiment supprimer votre compte",
  Settings: "Réglages",
  SignOut: "Supprimer le compte",
  TryAnotherPicture: "Essayer une autre image",
  AddNicePicture: "Ajouter une belle photo de vous",
  UploadAnotherPhoto: "TÉLÉCHARGER UNE AUTRE PHOTO",
  WeCouldNotSeeYouClearly:
    "Nous ne pourrions pas vous voir clairement sur la photo",
  PleaseAddAnother: "Veuillez en ajouter un autre",
  Subscriptions: "Abonnements",
  YourSubscriptionIs: "Votre abonnement est",
  Active: "actif",
  Cancelled: "annulé",
  NextDueDate: "Prochaine date d'échéance",
  ExpiryDate: "Date d'expiration",
  Unsubscribe: "Se désabonner",
  AlreadyUnsubscribed: "Déjà désabonné",
  UploadPhoto: "TÉLÉCHARGER LA PHOTO",
  Language: "Langue",
  SureChangeLanguage: "Êtes-vous sûr de vouloir changer de langue ?",
  Feedback: "Retour",
  JoinUkuthanda: "Rejoindre Man ak yow",
  MakeMeaningfulConnections: "Créer des liens significatifs",
  ChooseInterests: "Choisir les centres d'intérêt",
  Save: "Enregistrer",
  AddTagsThatDescribe:
    "Ajoutez des tags qui vous décrivent le mieux pour rencontrer des personnes partageant les mêmes idées",
  AddAtleast: "Ajoutez au moins 2 balises",
  IntroduceYourself:
    "Parlez nous de vous en toute authenticité. Soyez courtois ! Merci de ne pas mettre de message ou lien web au contenu abusive ou explicite.",
  Edit: "Éditer",
  // WriteAboutYourself: "Write about yourself",
  MyStory: "Mon histoire",
  MyPersonalTags: "Mes étiquettes personnelles",
  Unmatch: "Ne pas correspondre",
  BlocknReport: "Bloquer et signaler",
  AreYouSureToUnmatch: "Êtes-vous sûr? Dites-nous pourquoi",
  InapMesg: "Messages inappropriés",
  InapPhotos: "Photos inappropriées",
  FeelLikeSpam: "Sentez-vous comme du spam",
  Other: "Autre",
  NoReason: "Sans raison",
  HelpUsUnderstand: "Aidez-nous à comprendre le problème",
  ReportAProfile:
    "Lorsque vous signalez un profil, vous nous aidez à garder la communauté propre. Dites-nous pourquoi cette personne ne devrait pas être sur Man ak yow. Ne t'inquiète pas, ils ne le découvrent pas",
  InproperConduct: "Conduite inappropriée",
  AbusiveLang: "Langage abusif",
  SendSpamMsg: "Envoie des spams",
  MarriedRel: "Marié/En couple",
  Misrepresented: "Informations déformées",
  Cancel: "Annuler",
  ReportUser: "Dénoncer un utilisateur",
  Connected: "Liée",
  ConnectionFailed: "La connexion a échoué. Nouvelle tentative de connexion",
  From18YearsOld: "A partir de 18ans",
}

export const arLanguage = {
  AboutMe: "عني",
  Submit: "إرسال",
  KeepItClean:
    "حافظ على نظافتها! لا توجد تفاصيل رسول الهاتف روابط الويب المحتوى المسيء أو الفاضح من فضلك",
  NotAbleToLogIn: "لا يمكننا تسجيل دخولك",
  WriteUsToResolveQuery:
    "يرجى الكتابة إلينا في مربع الرسائل أدناه لحل استفسارك.",
  YouMatchedWith: "أنت تطابق مع",
  Connecting: "توصيل",
  SaySomethingWitty: "قل شيئا بارعا",
  Community: "تواصل اجتماعي",
  Search: "بحث",
  CrushReceived: "تلقت سحق",
  CrushesReceivedLikeBack: "تم تلقي عمليات التهشم مثل العودة إلى الدردشة",
  ThatAllForNow: "هذا كل شئ حتى الان",
  HaveMoreGreatPeople:
    "سيكون لدينا المزيد من الأشخاص الرائعين في غضون فترة وجيزة",
  NoMoreCards: "لا مزيد من البطاقات",
  Me: "أنا",
  Discover: "يكتشف",
  Matchbox: "علبة الثقاب",
  Hi: "مرحبا",
  WhatIsYourDOB: "ما هو تاريخ ميلادك",
  Next: "التالي",
  NoAccessUnder18:
    "ممنوع الوصول لمن هم أقل من 18 عامًا. يمكنك المحاولة مرة أخرى في عيد ميلادك الثامن عشر.",
  FirstImpressionMakeItCount:
    "تذكر ملفك الشخصي هو أول انطباع تقوم به. اجعلها ذات قيمة.",
  TeamWoo: "فريق وو",
  Incompatible: "غير متوافقAkuhambisani",
  PleaseReviewOurTips:
    "يرجى مراجعة نصائحنا للتأكد من الموافقة على صورك وإعجابها",
  PhotoTips: "تلميحات حول الصورAmathiphu esithombe",
  EditProfile: "تعديل الملف الشخصي",
  EmailIsNotValid: "البريد الإلكتروني غير صالح",
  PhoneNumberNotValid: "رقم الهاتف غير صالح",
  FeedbackTooShort: "التعليقات قصيرة جدًا",
  PleaseProvideAllDetails: "الرجاء تقديم كافة التفاصيل",
  ThankYouForFeedback: "شكرا لك على ملاحظاتك",
  WeWillGetInTouch: "سنتواصل معك قريبًا لفهم المزيد من التفاصيل",
  ContactUsDirectlyAt: "يمكنك الاتصال بنا مباشرة على",
  UseFormBelow: "أو استخدام النموذج أدناه",
  YourEmail: "بريدك الالكتروني",
  PleaseEnterEmail: "رجاءا أدخل بريدك الإلكتروني",
  YourPhoneNumber: "رقم تليفونك",
  PleaseEnterPhoneNumber: "يرجى إدخال رقم الهاتف الخاص بك",
  YourFeedback: "تعليقاتك",
  ShareYourFeedback: "شارك بتعليقاتك",
  SUBMIT: "إرسال",
  MyGender: "جنسي",
  IAm: "انا",
  AMan: "رجل",
  AWoman: "امراة",
  ShowToRelevantprofiles: "نحتاج إلى هذا لعرض الملفات الشخصية ذات الصلة",
  SelectYourGender: "اختر جنسك",
  ExploreSingles: "اكتشف الأغاني المنفردة",
  AlreadyHaveProfile: "لدي بالفعل ملف تعريف على LuvingTale",
  ByLoggingIn: "بتسجيل الدخول فإنك توافق على",
  Terms: "شروط",
  And: "و",
  PrivacyPolicy: "سياسة الخصوصية",
  WOOHOO: "WOOHOO",
  LikesYouToo: "معجب بك أيضا",
  StartChatting: "ابدأ الدردشة",
  KeepSwiping: "الاحتفاظ بالتمرير",
  WhatIsYourCurrentLocation: "ما هو موقعك الحالي",
  ShowYouProfilesAroundYou: "سنستخدم هذا لعرض الملفات الشخصية من حولك",
  NewMatch: "مباراة جديدة",
  UnlockWithWOOPlus: "الغاء القفل WOO PLUS",
  DiscoverPeople: "اكتشف الناس",
  SeeWhoLikedYou: "تعرف على من أعجبك وأحب الاتصال مرة أخرى",
  LikesVisits: "إبداءات الإعجاب والزيارات",
  YourFutureLikesVisits: "زياراتك المستقبلية تحب",
  LikedProfiles: "الملفات الشخصية التي أعجبتك",
  ProfilesYouLike: "الملفات الشخصية التي تعجبك ستظهر هنا",
  SkippedProfiles: "الملفات الشخصية التي تم تخطيها",
  SkippedProfilesWillShowHere: "ستظهر الملفات الشخصية التي تم تخطيها هنا",
  CrushReceived: "تلقت سحق",
  FutureCrushWillShowHere: "سيظهر سحقك المستقبلي هنا",
  YouHaveNoMatchesYet: "ليس لديك مباريات حتى الآن",
  InTheMeantime: "حضور",
  GetDiscoveredByMorePeople: "اكتشف المزيد من الأشخاص",
  ViewProfile: "عرض الصفحة الشخصية",
  Purchases: "المشتريات",
  MyPurchases: "مشترياتي",
  Store: "متجر",
  Plus: "زائد",
  MyNameIs: "اسمي هو",
  FullName: "الاسم الكامل",
  Mandatory: "إلزامي",
  FullNameShouldNotBeBlank: "يجب ألا يكون الاسم الكامل فارغًا",
  TheCharacter: "الشخصية",
  IsNotAllowed: "غير مسموح",
  CareAboutPrivacy:
    "نحن نهتم بخصوصيتك ونعرض فقط الأحرف الأولى من كلمة نساء 'و' الاسم الكامل للرجال",
  AllOutOfLikes: "كل من الإعجابات",
  GetMoreLikesTomorrow: "احصل على المزيد من الإعجابات غدًا",
  Or: "أو ",
  GetUnlimitedLikes: "احصل على لايكات غير محدودة",
  PhotoTips: "تلميحات حول الصور",
  PurposeOfPhotoGuidelines:
    "الغرض من إرشادات الصور هو أن تجعلك تبدو جيدًا وأن يشعر الأعضاء الآخرون بالراحة في الاتصال بك.",
  PhotosOfYourself: "صور لنفسك - صور سيلفي أو صور شخصية واضحة هي الأفضل",
  SoloSelfie: "يجب أن تظهر لك صور فردية / صور شخصية - وليس",
  ConsideredIncompatible:
    "إذا تم اعتبار أي صورة غير متوافقة بعد الفحص الفني واليدوي لـ LuvingTale ، فستتم إزالتها من ملفك ",
  AvoidGroupPhotos: "تجنب الصور الجماعية - من الصعب معرفة هويتك",
  DontUploadCouplesPhotos:
    "لا تحمّل صور الأزواج. يجعلك تبدو وكأنك بالفعل في علاقة.",
  DontUploadPhotosWithChildren:
    "لا تقم بتحميل الصور مع الأطفال - فنحن جميعًا بالغون هنا",
  GotIt: "فهمتك",
  MyLocation: "موقعي",
  DistanceFromMe: "المسافة مني",
  Miles: "اميال",
  AgeRange: "الفئة العمرية",
  GenderPreferences: "تفضيلات الجنس",
  Notifications: "إشعارات",
  MatchAlert: "تنبيه المباراة",
  ChatMessage: "رسالة محادثة",
  CrushReceived: "تلقت سحق",
  AnswersReceived: "تم تلقي الإجابات",
  SoundAlerts: "تنبيهات صوتية",
  HideMeMutualFriends: "خبئني في الأصدقاء المشتركين",
  CannotSeeMutualFriendsEither: "عندما لا يمكنك رؤية الأصدقاء المشتركين أيضًا",
  WantSignOut: "هل أنت متأكد من أنك تريد تسجيل الخروج",
  Settings: "إعدادات",
  SignOut: "خروج",
  TryAnotherPicture: "جرب صورة أخرى",
  AddNicePicture: "أضف صورة جميلة لك",
  UploadAnotherPhoto: "تحميل صورة أخرى",
  WeCouldNotSeeYouClearly: "لن نراكم بوضوح في الصورة",
  PleaseAddAnother: "الرجاء إضافة آخر",
  Subscriptions: "شراكات",
  YourSubscriptionIs: "اشتراكك هو",
  Active: "نشيط",
  Cancelled: "ألغيت",
  NextDueDate: "موعد الاستحقاق التالي",
  ExpiryDate: "تاريخ الانتهاء",
  Unsubscribe: "إلغاء الاشتراك",
  AlreadyUnsubscribed: "غير مشترك بالفعل",
  UploadPhoto: "حمل الصورة",
  Language: "لغة",
  SureChangeLanguage: "هل أنت متأكد من تغيير اللغة",
  Feedback: "تعليق",
  JoinUkuthanda: "الانضمام إلى LuvingTale",
  MakeMeaningfulConnections: "عمل اتصالات ذات مغزى",
  ChooseInterests: "اختر الاهتمامات",
  Save: "يحفظ",
  AddTagsThatDescribe:
    "أفضل لمقابلة أشخاص متشابهين في أضف العلامات التي تصفك بشكل التفكير",
  AddAtleast: "أضف علامتين على الأقل",
  IntroduceYourself:
    "قدم نفسك بقصتك. اجعلها حقيقية ومثيرة للاهتمام. حافظ على نظافتها! لا هاتف أو تفاصيل برنامج المراسلة أو روابط الويب أو المحتوى المسيء أو الفاضح من فضلك",
  Edit: "تعديل",
  MyStory: "قصتي",
  MyPersonalTags: "علاماتي الشخصية",
  From18YearsOld: "من سن 18 سنة",
}

export const zuLanguage = {
  AboutMe: "Mayelana nami",
  Submit: "Hambisa",
  KeepItClean:
    "Kugcine kuhlanzekile!Akungabi khona ucingo, imininingwane yezithunywa,izixhumanisi ze webhu, okuhlukumezayo,okuqukethwe okusobala siyacela",
  NotAbleToLogIn: "Asikwazi ukuku ngenisa ngemvume",
  WriteUsToResolveQuery:
    "Sicela usibhalele ebhokisini lemilayezo elingezansi ukuxazulula umbuzo wakho.",
  YouMatchedWith: "Ufanise ne-",
  Connecting: "Iyaxhuma",
  SaySomethingWitty: "Isho okuthile okuhlakaniphile",
  Community: "Umphakathi",
  Search: "Sesha",
  CrushReceived: "Ukuchoboza kutholakele",
  CrushesReceivedLikeBack: "Izichobozo zitholakele, njengoku buyela kwi ngxoxo",
  ThatAllForNow: "Yilokho kuphela okwamanje",
  HaveMoreGreatPeople: "Sizoni phathela abantu abakhulu kakhulu maduze nje",
  NoMoreCards: "Awasekho amakhadi",
  Me: "Mina",
  Discover: "Thola",
  Matchbox: "Ibhokisi lokufanisa",
  Hi: "Sawubona",
  WhatIsYourDOB: "Luthini usuku lwakho loku zalwa",
  Next: "OLANDELAYO",
  NoAccessUnder18:
    "Abanga phansi kweminyaka eyi shumi neshaga lombili abavunyelwe, ungazama futhi ngosuku lwakho lokuzalwa uhlanganisa iminyaka eyi shumi neshaga lombili",
  FirstImpressionMakeItCount:
    "Khumbula, iphrofayili yakho umbono wokuqala owenzayo. Kwenze kubalwe.",
  TeamWoo: "Ithimba le Woo",
  Incompatible: "Akuhambisani",
  PleaseReviewOurTips:
    "Sicela ubuyekeze amathiphu ethu ukuze uqinisekise ukuthi izithombe zakho zivunyelwe futhi zithandwe",
  PhotoTips: "Amathiphu esithombe",
  EditProfile: "Hlela iphrofayili",
  EmailIsNotValid: "I-imeyili ayivumelekile",
  PhoneNumberNotValid: "Inombolo yocingo ayivumelekile",
  FeedbackTooShort: "Impendulo imfishane kakhulu",
  PleaseProvideAllDetails: "Sicela unikeze yonke imininingwane",
  ThankYouForFeedback: "Siyabonga ngempendulo yakho",
  WeWillGetInTouch:
    "Sizothintana nawe kungekudala ukuqonda kabanzi ngemininingwane",
  ContactUsDirectlyAt: "Ungaxhumana nathi ngqo ku",
  UseFormBelow: "noma usebenzise ifomu elingezansi",
  YourEmail: "Imeyili yakho",
  PleaseEnterEmail: "Sicela ufake ikheli lakho le-imeyili",
  YourPhoneNumber: "Inombolo Yakho Yocingo",
  PleaseEnterPhoneNumber: "Sicela ufake inombolo yakho yocingo",
  YourFeedback: "Impendulo yakho",
  ShareYourFeedback: "Yabelana ngempendulo yakho",
  SUBMIT: "HAMBISA",
  MyGender: "Ubulili bami",
  IAm: "Ngi",
  AMan: "Owesilisa",
  AWoman: "Owesifazane",
  ShowToRelevantprofiles:
    "Sidinga loku ukuku khombisa amaphrofayili afanelekile",
  SelectYourGender: "Khetha ubulili bakho",
  ExploreSingles: "Hlola abanga shadile",
  AlreadyHaveProfile: "Sengi nayo iphrofayili ku Ukuthanda",
  ByLoggingIn: "Ngoku ngena ngemvume uyavumelana",
  Terms: "nemigomo",
  And: "ne",
  PrivacyPolicy: "nqubo mgomo yemfihlo",
  WOOHOO: "WOOHOO",
  LikesYouToo: "ukukhonzile nawe",
  StartChatting: "qala ingxoxo",
  KeepSwiping: "qhubeka noku swayipha",
  WhatIsYourCurrentLocation: "Ukuphi njenga manje",
  ShowYouProfilesAroundYou:
    "Sizo sebenzisa loku ukuku khombisa amaphrofayili aseduze nawe",
  NewMatch: "Umaqondana omusha",
  UnlockWithWOOPlus: "Vula nge WOO PLUS",
  DiscoverPeople: "Thola abantu",
  SeeWhoLikedYou:
    "Bona ubani oku thandile bese nawe uyamthanda ukuze uxhumane naye",
  LikesVisits: "abaku thandile nabaku vakashele",
  YourFutureLikesVisits: "abasazoku thanda ngokuzayo bazovela la",
  LikedProfiles: "amaphrofayela owathandile",
  ProfilesYouLike: "amaphrofayela owathandile azovela la",
  SkippedProfiles: "amaphrofayela ajombiwe",
  SkippedProfilesWillShowHere: "amaphrofayela ajombiwe azovela la",
  CrushReceived: "Oqokiwe wamukelwe",
  FutureCrushWillShowHere: "Oqokiwe wakho wangoku zayo uzovela",
  YouHaveNoMatchesYet: "awukabi naye oqondene nawe",
  InTheMeantime: "okwesikhashana",
  GetDiscoveredByMorePeople: "vela kwabanye abantu abadlulele",
  ViewProfile: "bheka iphrofayela",
  Purchases: "okuthengwayo",
  MyPurchases: "engiku thengayo",
  Store: "istolo",
  Plus: "ne",
  MyNameIs: "igama lami u",
  FullName: "Amagama aphelele",
  Mandatory: "okuphoqelekile",
  FullNameShouldNotBeBlank: "kumagama aphelele akufanele unga gcwalisi",
  TheCharacter: "uhlamvu",
  IsNotAllowed: "aluvumelekile",
  CareAboutPrivacy:
    "Sikukhathelele ukufihla kwakho ngakho sizoveza iziqalo kubantu besifazane namagama aphelele kwabesilisa",
  AllOutOfLikes: "konke okuthandiwe",
  GetMoreLikesTomorrow: "thola okuthandiwe okuningi kusasa",
  Or: "noma ",
  GetUnlimitedLikes: "uthole okuthandiwe okungena mkhawulo",
  PhotoTips: "amathiphu ezithombe",
  PurposeOfPhotoGuidelines:
    "injongo yemihlahlandlela yezithombe ukuthi zikwenza ubukeke kahle nokuthi amanye amalungu azizwe ekhululekile ukuxhumana nawe",
  PhotosOfYourself: "izithombe zakho,eziqondekayo, nezicacile izona ezinhle",
  SoloSelfie:
    "izithombe ushuthe ngawedwana fanele kubonakale wena hayi omunye umuntu",
  ConsideredIncompatible:
    "uma kuke kwakhona isithombe esibonakala singa fanele emva kokuba ochwepheshe bebhekile kwahlolwa nange zandla iphrofayela yakho izosuswa",
  AvoidGroupPhotos: "gwema izithombe nibaningi, kunzima ukubona uwena oyimuphi",
  DontUploadCouplesPhotos:
    "ungazifaki izithombe zabantu asebe qokene loku kuzo bonakala engathi usekhona ohlekisana naye",
  DontUploadPhotosWithChildren:
    "ungazifaki izithombe zabantwana sibadala sonke la",
  GotIt: "uyathola",
  MyLocation: "indawo yami",
  DistanceFromMe: "ibanga elisuka kimi",
  Miles: "amamayela",
  AgeRange: "ibanga lobudala",
  GenderPreferences: "ukukhetha ubulili",
  Notifications: "izaziso",
  MatchAlert: "isaziso sika maqondana",
  ChatMessage: "ingxoxo mlayezo",
  CrushReceived: "ochotshoziwe otholakele",
  AnswersReceived: "izimpendulo ezitholakele",
  SoundAlerts: "izaziso zomsindo",
  HideMeMutualFriends: "ngifihle kubangane bethu sobabili",
  CannotSeeMutualFriendsEither:
    "mengi khona angikwazi ukubona abangane bethu sobabili futhi",
  WantSignOut: "unesiqiniseko ufuna ukuphuma ngemvume",
  Settings: "izilungiselelo",
  SignOut: "phuma ngemvume",
  TryAnotherPicture: "zama esinye isithombe",
  AddNicePicture: "faka isithombe esihle sakho",
  UploadAnotherPhoto: "LAYISHA ESINYE ISITHOMBE",
  WeCouldNotSeeYouClearly: "asikwazanga ukukubona kahle kulesi sithombe",
  PleaseAddAnother: "sicela ufake esinye",
  Subscriptions: "okubhaliselwe",
  YourSubscriptionIs: "okwakho okubhaliselwe ku",
  Active: "yasebenza",
  Cancelled: "kususiwe",
  NextDueDate: "usuku oluqokiwe olulandelayo",
  ExpiryDate: "usuku eliphelelwa ngalo",
  Unsubscribe: "zikhiphe ohlwini",
  AlreadyUnsubscribed: "usuvele ukhishiwe ohlwini",
  UploadPhoto: "LAYISHA ISITHOMBEO",
  Language: "Ulimi",
  SureChangeLanguage: "Uqinisekile ukushintsha ulimi",
  Feedback: "Impendulo",
  JoinUkuthanda: "Joyina Ukuthanda",
  MakeMeaningfulConnections: "Yenza Ukuxhumana Okunenjongo",
  ChooseInterests: "Khetha Izintshisekelo",
  Save: "gcina",
  From18YearsOld: "Kusukela eminyakeni engu-18",
}
