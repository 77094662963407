export function Translate(key = "") {
  // console.log("key " + key);
  let langDt = localStorage.getItem("ukuLang");

  if (langDt) {
    let res = JSON.parse(langDt);
    if (key) {
      return res[key];
    } else {
      return res;
    }
  }
}
