
import {
    SET_LOGIN_DATA,
    API_START,
    API_END,
    DO_LOGIN, API_ERROR, SET_PHOTO_DATA, PHOTO_UPLOAD,SET_CHAT_PHOTO_DATA, CLEAR_ALBUM,TRUECALLER_DETAILS_REQUEST,
    SET_DISCOVER_DATA, SET_DISCOVER_DATA_PAGINATION, DO_DISCOVER, GET_VISITOR, SET_VISITOR_DATA,
    SET_TRUECALLER_DATA,SET_CSRF_TOKEN,SET_ACCESS_TOKEN_BEFORE_VERIFICATION,SET_ACCESS_TOKEN_AFTER_VERIFICATION,
    CHECKSUM_GENERATED,GET_USER_PROFILE,SET_LIKEDME_DATA,SET_SKIPPED_DATA,SET_CRUSHES_DATA,SET_LIKEDBYME_DATA,
    LIKE_USER,DISLIKE_USER,GET_MATCHES
  } from "../actions/types";
  
  export default function(state = {login:{}, album:{}}, action) {
    console.log("action type => ", action.type);
    console.log("payload",action.payload)
    console.log("state in remote",state)
    console.log("error in remote",action)
    const now = Date.now();
    const user = localStorage.getItem("user");
    const userObj = user ? JSON.parse(user) : null;
    switch (action.type) {
      case SET_CSRF_TOKEN:
        return {
          ...state,
           csrfToken: action.payload["csrfToken"],
           trueCallerDataFound:null,
          };
      case SET_LOGIN_DATA:
        return {
          ...state,
           login: action.payload,
          };
          case GET_MATCHES:
            return {
              ...state,
               matches: action.payload,
              };
      case CHECKSUM_GENERATED:
          return {
            ...state,
             checksumData: action.payload,
            };
      case GET_USER_PROFILE:
          console.log('userProfile=', action.payload)
          const wooAlbum = (action.payload && action.payload.wooAlbum) || [];
          if (wooAlbum.length > 9) wooAlbum.length = 9;
          return {
            ...state,
             userProfile: {...action.payload, wooAlbum: wooAlbum},
            };

      case LIKE_USER:
        return {
          ...state,
          like_statusCode: action.payload['statusCode'],
          match:action.payload['match'],
          disLike_statusCode: "",
          like_timeStamp:now,
          dislike_timeStamp:0
        }

      case DISLIKE_USER:
        return {
                ...state,
                disLike_statusCode: action.payload,
                like_statusCode: "",
                dislike_timeStamp:now,
                like_timeStamp:0
              };
          
      case SET_TRUECALLER_DATA:
        return {
          ...state,
          trueCallerDataFound: action.payload["isSuccess"],
          csrfToken:null
          };
      case SET_PHOTO_DATA:
        return {
          ...state,
           album: action.payload,
           userProfile: {...state.userProfile, wooAlbum: action.payload.wooAlbum},
          };
      case SET_CHAT_PHOTO_DATA:
            return {
              ...state,
              chatPhotoData: action.payload,
              };
      case SET_ACCESS_TOKEN_BEFORE_VERIFICATION:
          return {
            ...state,
            accessTokenBeforeVerification: action.payload["accessToken"],
            };
      case SET_ACCESS_TOKEN_AFTER_VERIFICATION:
          return {
            ...state,
            accessTokenAfterVerification: action.payload["accessToken"],
            };
      case SET_DISCOVER_DATA_PAGINATION:
        let discoverProfiles = []
        if (userObj.hasOwnProperty('discover') === true){
        const oldDiscoverData = userObj.discover
         discoverProfiles = oldDiscoverData
        }
        let newDiscoverData = action.payload
        const newDiscoverProfiles = newDiscoverData.discoverCardDtos
        const updatedDiscoverProfiles = discoverProfiles.concat(newDiscoverProfiles)
        console.log("All discoverProfiles",updatedDiscoverProfiles)
        console.log("new discoverData before",newDiscoverData)
        newDiscoverData = {
            ...newDiscoverData,
            discoverCardDtos:updatedDiscoverProfiles
        }
        console.log("new discoverData after",newDiscoverData)
          return{
            ...state,
            discover:newDiscoverData
          };
      case SET_DISCOVER_DATA:
          return{
            ...state,
            discover:action.payload
          };
      case SET_VISITOR_DATA:
        return{
          ...state,
          visitor:action.payload
        }
      case SET_SKIPPED_DATA:
        return{
          ...state,
          skippedProfiles:action.payload
        }  
      case SET_CRUSHES_DATA:
        return{
          ...state,
          crushes:action.payload
        } 
      case SET_LIKEDBYME_DATA:
        return{
           ...state,
           likedByMe:action.payload
        } 
        case SET_LIKEDME_DATA:
          return{
            ...state,
            likedMe:action.payload
          }  
      case CLEAR_ALBUM:
        return {
          ...state,
          album :{}
        }   
      case API_START:
        if (action.payload === DO_LOGIN 
          || action.payload === PHOTO_UPLOAD 
          || action.payload === DO_DISCOVER
          || action.payload === GET_VISITOR
          || action.payload == TRUECALLER_DETAILS_REQUEST
          || action.payload === GET_MATCHES) {
          return {
            ...state,
            isLoadingData: true
          };
        }
        else{
          return {
            ...state,
            isLoadingData: false
          };
        }
        break;
      case API_END:
          if (action.payload === DO_LOGIN 
            || action.payload === PHOTO_UPLOAD 
            || action.payload === DO_DISCOVER
            || action.payload === GET_VISITOR
            || action.payload === GET_MATCHES) {
              return {
                ...state,
                isLoadingData: false
              };
            }
        else if (action.payload == TRUECALLER_DETAILS_REQUEST){
          return {
            ...state,
            isLoadingData: true
          };
        }
        else{
          return {
            ...state,
          };
        }
        break;
      case API_ERROR:
        console.log("API_ERROR is called")
          return{
            ...state,
            isLoadingData:false
          }
      default:
        return state;
    }
  }
  