import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../../withRoot';
import styles from './style'
import Button from '@material-ui/core/Button';

class LoadingButton extends React.Component{
    
    render(){
        const {children,disabled,loading,...rest} = this.props
        const isLogin = this.props.isLogin
        let loaderClassName = "dot-typing"
        if (isLogin && isLogin == true){
          loaderClassName = "dot-typing-login"
        }
        return (
            <Button disabled={disabled} {...rest} disableRipple={this.props.disableRipple ? true : false} >
              {children}
              {loading && <div className={loaderClassName} ></div>}
            </Button>
          )
    }
}

const styledComponent = withRoot(withStyles(styles)(LoadingButton))
export {styledComponent as LoadingButton}