import Api from './api'
import {API} from '../actions/types'
import {apiError, apiStart, apiEnd } from "../actions/api_actions";
import JSONbig from 'json-bigint'
import {history} from '../helpers/history'

export default ({ dispatch }) => next => action => {
    next(action);
  
    if (action.type !== API) return;
  
    const {
      url,
      method,
      multipart,
      data,
      onSuccess,
      label,
      login,
      callback,
      statusCodeCallBack,
      failureCallback,
      handleLargeNumber,
      onFail,
    } = action.payload;
  
    if (label) {
      dispatch(apiStart(label));
    }
  
    Api
      .apiCall(url, data, method, login, multipart)
      .then( response  => {
          if(!response.ok){
            if(response.status === 401){
              if (login == false){
              localStorage.removeItem('user');
              history.replace("/")
              }
              return Promise.reject(response.status);
            }
            return Promise.reject(response.status);
          }
          console.log("response status",response.status)
          console.log("label name",label)
          const result = handleLargeNumber ? response.text() : response.json()
          console.log('result value in like api',response)
          statusCodeCallBack(response.status)
          if (label == "LIKE_USER" || label == "DISLIKE_USER"){
            if (label == "LIKE_USER"){
              if (response.status == "202"){
                const likeData = {
                  statusCode:response.status,
                  match:null
                }
                dispatch(onSuccess(likeData));
              }
            }
            else{
            dispatch(onSuccess(response.status));
            }
          }
          return result;
      }).then(data =>{
        console.log("data in apicall",data)
        const result = handleLargeNumber ? JSONbig.parse(data) : data;
        console.log('result and label',result,label)
          if (label == "LIKE_USER"){
            if (result){
            const likeData = {
              statusCode:200,
              match:result
            }
            console.log('likeData returned',likeData)
            dispatch(onSuccess(likeData));
          }
          }
          else{
            dispatch(onSuccess(result));
          }
        callback(result)
      })
      .catch(error => {
        dispatch(apiError(error));
        failureCallback(error);
        onFail && dispatch(onFail(error))
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  };
