
import {
    GENDER_SELECT
  } from "../actions/types";
  
export default function(state = {}, action) {
    console.log("action type => ", action.type);
    switch (action.type) {
        case GENDER_SELECT:
            return { 
                ...state,
                gender: action.payload 
            };
        default:
            return state;
    }
}
  