import React from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import withRoot from "../../withRoot";
import { connect } from "react-redux";
import {
  appLaunch,
  runDiscover,
  callDiscover,
  saveFirebaseToken,
  generateCheckSum,
  purchaseProduct,
  logout,
} from "../../actions/user_actions";
import Paper from "@material-ui/core/Paper";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Loading from '../../components/Loading/Loading';

// import { ScreenContainer } from "../../components/ScreenContiner/ScreenContainer";
// import { DiscoverCardTemplate } from "../../components/Card/DiscoverCardTemplate";
// import MeScreen from "../MeScreen/MeScreen";
// import { MatchBoxScreen } from '../Matchbox/MatchBoxScreen'
import TabVisibility from "react-tab-visibility";
import { isBrowser } from "react-device-detect";
import "font-awesome/css/font-awesome.css";

import { loadCSS } from "fg-loadcss/src/loadCSS";

import {
  onClickAndroidBadge,
  onClickIOSBadge,
  imageCroppingServerURL,
} from "../../config";

import { history } from "../../helpers/history";
// import { PopUp } from "../../components/PopUp/PopUp";
// import { trackEventForUser } from "../../helpers/MixpanelTracker";
import { messaging } from "../../init-fcm";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import SvgIcon from "@material-ui/core/SvgIcon";
// import ItsAMatchView from "../ItsAMatchView/ItsAMatchView";
// import { DiscoverProfile } from "../DiscoverProfile/DiscoverProfile";
// import { PurchasePopup } from '../PurchasePopup/PurchasePopup'
// import PaymentOptionsView from '../PaymentOptionsView/PaymentOptionsView'
import { PAYTM_STATUS_URL } from "../../config";
import {
  getPaytmPayload,
  getPaytmTransactionPayload,
} from "../../helpers/purchaseHelper";

import smartlookClient from "smartlook-client";

import loadable from "@loadable/component";
import { Translate } from "../../helpers";

const ScreenContainer = loadable(() =>
  import("../../components/ScreenContiner")
);
const DiscoverCardTemplate = loadable(() =>
  import("../../components/Card/DiscoverCardTemplate")
);
const MeScreen = loadable(() => import("../MeScreen/MeScreen"));
const MatchBoxScreen = loadable(() => import("../Matchbox"));
const ItsAMatchView = loadable(() => import("../ItsAMatchView/ItsAMatchView"));
const DiscoverProfile = loadable(() => import("../DiscoverProfile"));
const PurchasePopup = loadable(() => import("../PurchasePopup"));
const PaymentOptionsView = loadable(() =>
  import("../PaymentOptionsView/PaymentOptionsView")
);
const PopUp = loadable(() => import("../../components/PopUp/exportPopup"));

// const messaging = loadable(() => import('../../init-fcm'));

const MeIcon = (props) => (
  <SvgIcon {...props}>
    <g
      fill="none"
      fill-rule="evenodd"
      stroke={props.color}
      stroke-linejoin="round"
      stroke-width="2"
      transform="translate(3 4)"
    >
      <ellipse cx="8.5" cy="4.8" rx="4.857" ry="4.8" />
      <path d="M8.5 18H17c0-4.64-3.806-8.4-8.5-8.4S0 13.36 0 18h8.5z" />
    </g>
  </SvgIcon>
);
const DiscoverIcon = (props) => (
  <SvgIcon {...props}>
    <g
      fill="none"
      fill-rule="evenodd"
      stroke={props.color}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      transform="translate(3 2)"
    >
      <path d="M4.829 3.149A3 3 0 0 1 8.217.596l6.932.974a3 3 0 0 1 2.553 3.388l-1.53 10.893a3.001 3.001 0 0 1-2.82 2.58" />
      <rect width="13" height="17" y="4" rx="3" />
    </g>
  </SvgIcon>
);
const ChatIcon = (props) => (
  <SvgIcon {...props}>
    <g
      fill="none"
      fill-rule="evenodd"
      stroke={props.color}
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
    >
      <path d="M6 4h12a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3h-6l-6 3v-3a3 3 0 0 1-3-3V7a3 3 0 0 1 3-3zM6.53 8.53h9.529M6.53 11.706h6.267" />
    </g>
  </SvgIcon>
);
let userProps = null;

class DiscoverScreen extends TabVisibility {
  constructor(props) {
    super(props);
    this.popupRef = React.createRef();
    this.showDiscoverDataFromLocal = false;
    this.currentWooProduct = null;
    this.purchaseScreenType = "WOOPLUS";
    this.state = {
      screen: 1,
      tabIsVisible: true,
      isPaginationCall: false,
      showItsAMatchView: false,
      showPurchasePopup: false,
      showPaymentView: false,
      appSkin:null
    };
  }

  componentDidMount() {
    super.componentDidMount();
    console.log("this.props in discover", this.props);
    if (this.props.user) {
      userProps = this.props.user;
    } else {
      const user = localStorage.getItem("user");
      const userObj = user ? JSON.parse(user) : null;
      userProps = userObj;
    }
    console.log("userProps", userProps);
    if (userProps) {
      console.log("userProps are here", userProps);
      window.onpopstate = this.handlePopState;
      if (
        typeof this.props.location.state.showVisitor !== "undefined" &&
        this.props.location.state.showVisitor == true
      ) {
        this.setState({ screen: 0 });
      } else if (
        typeof this.props.location.state.showMatchBox !== "undefined" &&
        this.props.location.state.showMatchBox == true
      ) {
        this.setState({ screen: 2 });
      } else {
        console.log("userProps are here 2", userProps);
        const { discover } = this.props;
        console.log("userProps discover", discover);
        this.setState({ screen: 1 });
        this.checkAndGetNotiPermission();
        console.log("userProps this come");
        const manualAppLaunchCall = localStorage.getItem("manualAppLaunchCall");
        if (!discover || discover.length <= 0 || manualAppLaunchCall) {
          console.log("userProps and this come");
          this.props.appLaunch(userProps);
          localStorage.removeItem("manualAppLaunchCall");
          //this.props.runDiscover(userProps);
        }
      }
      // this.handleIdentify();
      let smartlookIdentifierFirstName = "";
      let smartlookIdentifierLastName = "";
      if (userProps.loginDto.firstName) {
        smartlookIdentifierFirstName = userProps.loginDto.firstName;
      }
      if (userProps.loginDto.lastName) {
        smartlookIdentifierLastName = userProps.loginDto.lastName;
      }
      smartlookClient.identify(userProps.wooId, {
        name: smartlookIdentifierFirstName + " " + smartlookIdentifierLastName,
        gender: userProps.gender,
        region: userProps.loginDto.region,
      });
      // smartlookClient.disable()
    } else {
      localStorage.removeItem("user");
      history.replace("/login");
    }
    loadCSS(
      "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css",
      document.querySelector("#insertion-point-jss")
    );
    smartlookClient.disable();
    setTimeout(() => {
      if (localStorage.getItem("billingErrorMessage")) {
        const msg = localStorage.getItem("billingErrorMessage");
        alert(msg);
        localStorage.removeItem("billingErrorMessage");
        const user = localStorage.getItem("user");
        let userObj = user ? JSON.parse(user) : null;
        this.props.logout(userObj);
        setTimeout(() => {
          localStorage.setItem("manualAppLaunchCall", "manualAppLaunchCall");
        }, 500);
      }
    }, 1000);
    const skin = localStorage.getItem("skin")
console.log("skin",skin)
let skinTheme = null
if(skin){
  console.log("skin-if",skin)
  skinTheme = JSON.parse(skin)
console.log("skinTheme-if", skinTheme )
this.setState({
  appSkin:skinTheme
})
}
console.log("skinTheme", skinTheme )
  }

  componentWillUnmount() {
    window.onpopstate = null;
    // this.handleDisable();
  }

  handlePopState = (event) => {
    event.preventDefault();
    if (this.state.screen == 1) {
      history.push("/login");
    } else if (this.state.screen == 0 || this.state.screen == 2) {
      this.setState({ screen: 1 });
      history.push("/");
    }
  };

  onTabVisibilityChange(status) {
    if (status.tabIsVisible) {
      if (userProps) {
        this.props.appLaunch(userProps);
        //this.props.runDiscover(userProps);
      }
      this.closePopup();
    }
  }

  matchTabClick = () => {
    this.openPopup();
  };

  openPopup = () => {
    this.popupRef.handleClickOpen();
  };

  openPurchasePopup = () => {};

  openDetailOfProfile = (userProfile) => {
    history.push({
      pathname: "/profileDetailScreen",
      state: {
        profileDetail: userProfile,
        type: "DISCOVER",
        needToSendSuccessFullPurchaseOnGtag: false,
      },
    });
  };

  closePopup = () => {
    this.popupRef.handleClose();
  };

  tabClick = (event, value) => {
    console.log("tabClick called");
    let currentValue = value;
    console.log("currentValue", currentValue);
    console.log("userProps=", userProps);
    console.log("this.props.location.state", this.props.location.state);
    this.showDiscoverDataFromLocal = false;
    if (currentValue == 0) {
      //trackEventForUser("ME_TAB_CLICKED");
    }
    if (currentValue == 2) {
      //54321
      // localStorage.removeItem("user");
      // history.replace("/login");
      //trackEventForUser("MATCH_TAB_CLICKED");
    }

    if (currentValue == 1) {
      //trackEventForUser("DISCOVER_TAB_CLICKED");
      this.showDiscoverDataFromLocal = true;
    }

    this.setState({ screen: currentValue });
  };

  downloadWooApp = (buttonType) => {
    console.log("screen value", this.state.screen);
    let eventName = "";
    if (buttonType == "iOS") {
      onClickIOSBadge();
      eventName = "_DOWNLOAD_IOS_APP";
    } else {
      onClickAndroidBadge();
      eventName = "_DOWNLOAD_ANDROID_APP";
    }
    if (this.state.screen == 0) {
      eventName = "VISITOR" + eventName;
    } else {
      eventName = "DISCOVER" + eventName;
    }
    console.log("eventName for discover", eventName);
    //trackEventForUser(eventName);
  };

  saveFireBaseTokenOnServer = (fireBaseToken) => {
    this.props.saveFirebaseToken(userProps, fireBaseToken);
  };

  async checkAndGetNotiPermission() {
    try {
      const token = await messaging.getToken();
      this.saveFireBaseTokenOnServer(token);
      console.log("Notification Token: " + token, this);
    } catch (err) {
      console.log("Unable to get permission to notify.", err);
    }
  }

  getCroppedImageUrlForImageUrlString(imageUrl) {
    let croppedImageUrlString = "";
    let widthForCropper = parseInt(window.innerWidth * 0.46);
    if (isBrowser) {
      widthForCropper = "300";
    }
    if (!imageUrl.includes(imageCroppingServerURL)) {
      croppedImageUrlString =
        imageCroppingServerURL +
        "?url=" +
        imageUrl +
        "&height=287" +
        "&width=" +
        widthForCropper;
    } else {
      croppedImageUrlString = imageUrl;
    }
    return croppedImageUrlString;
  }

  renderDiscover = () => {
    const discoverCards = [];
    const { discover, isLoadingData } = this.props;
    const { isPaginationCall } = this.state;
    console.log("loading data", isLoadingData);
    if (discover) {
      const cardDtos = discover.discoverCardDtos;
      console.log("cardDtos", cardDtos);
      console.log("Discover response", discover);
      console.log("peter", discover);

      if (cardDtos) {
        cardDtos.forEach((cardDto) => {
          if (cardDto.cardType == "PROFILE_CARD") {
            const cardInfo = cardDto.discoverUserInfoDto;
            const imageUrl =
              cardInfo.wooAlbum && cardInfo.wooAlbum.length > 0
                ? cardInfo.wooAlbum[0].srcBig
                : "";
            console.log("imageUrl in discover", imageUrl);
            const croppedImageUrl =
              this.getCroppedImageUrlForImageUrlString(imageUrl);
            console.log("cropped imageUrl in discover", croppedImageUrl);
            discoverCards.push(
              <DiscoverCardTemplate
                onClick={() => this.openDetailOfProfile(cardInfo)}
                blurred={false}
                imageUrl={croppedImageUrl}
                name={cardInfo.firstName}
                age={cardInfo.age}
                location={cardInfo.location}
              />
            );
          }
        });
      }
    }
    if (isLoadingData == false) {
      return discoverCards;
    } else {
      if (isPaginationCall == true) {
        return discoverCards;
      }
    }
  };

  closePuchasePopup = () => {
    this.setState({ showPurchasePopup: false });
  };

  makePaymentForProduct = (wooProduct) => {
    this.currentWooProduct = wooProduct;
    this.setState({ showPaymentView: false, showPurchasePopup: false });
  };

  getStripePurchaseToken = (stripeToken) => {
    console.log("stripeToken", stripeToken);
    const user = localStorage.getItem("user");
    const userObj = user ? JSON.parse(user) : null;
    console.log("this.currenWooProduct", this.currentWooProduct);
    const planID = this.currentWooProduct["planId"];

    const paymentParams = {
      planId: planID,
      purchaseToken: stripeToken["id"],
      productType: this.purchaseScreenType,
      purchaseChannel: "Stripe",
      nudgeDiscountFlag: false,
    };

    console.log("paymentParams", paymentParams);
    this.setState({ showPurchasePopup: false, showPaymentView: false });
    this.props.purchaseProduct(
      userObj,
      paymentParams,
      false,
      this.currentWooProduct
    );
  };

  dismissPurchaseDialog = () => {
    this.setState({ showPaymentView: false });
  };

  startPaytmProcess = () => {
    const { screenType } = this.props.location.state;
    let user = JSON.parse(localStorage.getItem("user"));
    user = {
      ...user,
      currentWooProduct: this.currentWooProduct,
      purchaseInitiatedScreen: "DISCOVER",
    };
    localStorage.setItem("user", JSON.stringify(user));
    let currency = "INR";
    if (this.currentWooProduct["priceUnit"] === "₹") {
      currency = "INR";
    } else {
      currency = "USD";
    }

    gtag("event", "initiate_checkout_MWeb", {
      value: this.currentWooProduct["price"],
      currency: currency,
    });

    const paytmPayload = getPaytmPayload(
      user,
      this.currentWooProduct,
      this.purchaseScreenType
    );

    console.log("paytmPayload", paytmPayload);
    this.props.generateCheckSum(user, paytmPayload, this.currentWooProduct);
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps", nextProps);
    if (
      nextProps.location.state.showVisitor !==
      this.props.location.state.showVisitor
    ) {
      if (nextProps.location.state.showVisitor === true) {
        this.setState({ screen: 0 });
      }
    }
    if (
      typeof nextProps.location.state.needToSendSuccessFullPurchaseOnGtag !==
      "undefined"
    ) {
      if (
        nextProps.location.state.needToSendSuccessFullPurchaseOnGtag == true
      ) {
        let currency = "INR";
        let value = 1;
        let user = JSON.parse(localStorage.getItem("user"));
        console.log("user['currentWooProduct']", user["currentWooProduct"]);
        if (user["currentWooProduct"] !== null) {
          const product = user["currentWooProduct"];
          if (product["priceUnit"] === "₹") {
            currency = "INR";
          } else {
            currency = "USD";
          }
          if (product["price"]) {
            value = product["price"];
          }
        }
        console.log("purchase success", value, currency);
        //firebase.analytics().logEvent('ecommerce_purchase', { 'value': value, 'currency': currency });
        // gtag("event", "conversion", {
        //   send_to: "AW-755754629/HKsECLCX07UBEIXNr-gC",
        //   value: value,
        //   currency: currency
        // });
        user = {
          ...user,
          currentWooProduct: null,
        };
        localStorage.setItem("user", JSON.stringify(user));
      }
    }

    if (nextProps.checksumData !== this.props.checksumData) {
      console.log("nextProps", nextProps, nextProps.checksumData);
      if (nextProps.checksumData != null) {
        this.checksumData = nextProps.checksumData;
        console.log("this.checksumData", this.checksumData);
        this.createFromForPaytmToProcessTransaction(this.checksumData);
      }
    }
  }

  createFromForPaytmToProcessTransaction = (checksumData) => {
    const payTmRequestParams = getPaytmTransactionPayload(
      checksumData,
      this.purchaseScreenType
    );
    console.log("payTmRequestParams", payTmRequestParams);
    let f = document.createElement("form");
    f.setAttribute("method", "post");
    f.setAttribute("name", "paymentForm");
    f.setAttribute("action", PAYTM_STATUS_URL);
    const _requestparams = payTmRequestParams || {};
    for (let key in _requestparams) {
      let i = document.createElement("input");
      i.type = "hidden";
      i.name = key;
      i.value = _requestparams[key];
      f.appendChild(i);
    }
    document.getElementsByTagName("body")[0].appendChild(f);
    document.paymentForm.submit();
  };

  performMatchBoxAction = () => {
    const user = localStorage.getItem("user");
    const userObj = user ? JSON.parse(user) : null;
    console.log("userObj['isPaidUser']", userObj["isPaidUser"]);
    if (userObj["isPaidUser"] == true) {
      this.setState({ screen: 1 });
    } else {
      this.setState({ showPurchasePopup: false });
    }
  };

  //   shouldComponentUpdate() {
  //     return false;
  // }

  render() {
    console.log("this.props", this.props);
    console.log("this.props.location", this.props.location);
    console.log("this.props.location.state", this.props.location.state);
    const skinLoader = localStorage.getItem("skinLoader")

    const { classes, isLoadingData } = this.props;
    const {
      screen,
      isPaginationCall,
      showItsAMatchView,
      showPurchasePopup,
      showPaymentView,
      appSkin
    } = this.state;

    let locationState = this.props.location.state;
    if (typeof locationState === "undefined") {
      // locationState = {
      //   showDataFromLocal:false,
      //   needToSendSuccessFullPurchaseOnGtag:false,
      //   settingsChanged:false
      // }
      console.log("location state undefined");
      const user = localStorage.getItem("user");
      let userObj = user ? JSON.parse(user) : null;
      console.log("location state undefined 2", userObj);
      this.props.logout(userObj);
      console.log("location state undefined 3");
      setTimeout(() => {
        window.location.reload();
      }, 250);
      return;
    }
    const {
      showDataFromLocal,
      needToSendSuccessFullPurchaseOnGtag,
      settingsChanged,
    } = locationState;

    let needToShowDataLocally = false;
    if (this.showDiscoverDataFromLocal === true || showDataFromLocal === true) {
      needToShowDataLocally = true;
    }
    console.log("Screen classes", classes);
    console.log("screen number", this.state.screen);
    console.log("this.props.location.state", this.props.location.state);
    return (
      <ScreenContainer>
        {showPaymentView === true && (
          <Dialog
            PaperProps={{
              style: {
                overflow: "visible",
                width: "100%",
                maxWidth: "400px",
                borderRadius: 8,
                "-webkit-animation": "discover-card-anim 0.5s",
                animation: "discover-card-anim 0.5s",
                minHeight: "220px",
                backgroundColor: "rgba(240, 242, 249, 1.0)",
              },
            }}
            open={showPaymentView}
            aria-labelledby="form-dialog-title"
            classes={{
              paperScrollPaper: classes.fullHeightDialog,
              scrollPaper: classes.paymentPaperDialog,
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <PaymentOptionsView
                startPaytmProcess={() => this.startPaytmProcess()}
                purchaseType={this.purchaseScreenType}
                getStripePurchaseToken={(token) => {
                  this.getStripePurchaseToken(token);
                }}
                open={showPaymentView}
                dismiss={this.dismissPurchaseDialog}
                wooProduct={this.currentWooProduct}
              />
              <span
                className="purchase-close-popup"
                onClick={this.dismissPurchaseDialog}
              >
                <img src="images/close-cross.svg" />
              </span>
            </DialogContent>
          </Dialog>
        )}
        {showPurchasePopup && (
          <Dialog
            classes={{ root: classes.purchaseDialogRoot }}
            PaperProps={{
              style: {
                overflow: "visible",
                width: "90%",
                maxWidth: "400px",
                borderRadius: 8,
                "-webkit-animation": "discover-card-anim 0.5s",
                animation: "discover-card-anim 0.5s",
              },
            }}
            onClose={this.closePuchasePopup}
            open={showPurchasePopup}
            aria-labelledby="form-dialog-title"
          >
            <DialogContent
              className={`${classes.dialogContent} ${classes.dialogContentRadius}`}
            >
              <PurchasePopup
                purchaseType={this.purchaseScreenType}
                onSelectProduct={(wooProduct) =>
                  this.makePaymentForProduct(wooProduct)
                }
              />
              <span
                className="purchase-close-popup"
                onClick={this.closePuchasePopup}
              >
                <img src="images/close-cross.svg" />
              </span>
            </DialogContent>
          </Dialog>
        )}
        {showItsAMatchView === true && (
          <Dialog
            maxWidth="sm"
            PaperProps={{
              style: {
                overflow: "visible",
                width: "100%",
                height: "100%",
                fullScreen: true,
                fullWidth: true,
              },
            }}
            onClose={this.closePuchasePopup}
            open={showItsAMatchView}
            aria-labelledby="form-dialog-title"
            classes={{
              paperScrollPaper: classes.fullHeightDialog,
            }}
          >
            <DialogContent className={classes.dialogContent}>
              <ItsAMatchView user={userProps} />
            </DialogContent>
          </Dialog>
        )}
        {screen == 1 && isLoadingData && !isPaginationCall && (
          <div style={{ display: "flex", justifyContent: "center" }}>
            {/* <img
              style={{
                width: "70px",
                height: "50px",
                position: "fixed",
                top: "50%",
              }}
            // src="images/loader.gif"
            src="http://d2pd5v9y7ukdgh.cloudfront.net/Chatezee/chatezee.png"
            ></img> */}
            {/* <Loading isOverlay={true} url={appSkin.theme.favicon} /> */}
            <Loading isOverlay={true} url={skinLoader} />
            
            {console.log("companyLogo1")}
          </div>
        )}
        <PopUp
          screenType={screen}
          ref={(instance) => {
            this.popupRef = instance;
          }}
        />
        {appSkin && <Paper square className={classes.root} style={{background:appSkin.sidebarBgColor}}>
          <Tabs
            value={screen.toString()}
            variant="fullWidth"
            indicatorColor="primary"
            textColor="primary"
            onChange={this.tabClick}
            aria-label="Icon tabs example"
          >
            <Tab
              className={classes.label}
              classes={{ root: classes.rootTab }}
              value="0"
              icon={
                <MeIcon
                  className={"icon"}
                  color={screen == 0 ? appSkin.primaryColor : appSkin.navbarColor}
                />
              }
              aria-label="Phone"
              label={Translate("Me")}
            />
            <Tab
              className={classes.label}
              classes={{ root: classes.rootTab }}
              value="1"
              icon={
                <DiscoverIcon
                  className={"icon"}
                  color={screen == 1 ? appSkin.primaryColor : appSkin.navbarColor}
                />
              }
              aria-label="Discover"
              label={Translate("Discover")}
            />
            <Tab
              className={classes.label}
              classes={{ root: classes.rootTab }}
              value="2"
              icon={
                <ChatIcon
                  className={"icon"}
                  color={screen == 2 ? appSkin.primaryColor : appSkin.navbarColor}
                />
              }
              aria-label="Matchbox"
              label={Translate("Matchbox")}
            />
          </Tabs>
        </Paper>}
        {screen == 0 && (
          <div>
            <MeScreen user={userProps} />
          </div>
        )}
        {screen == 1 && (
          <div>
            <DiscoverProfile
              settingsChanged={settingsChanged}
              needToSendSuccessFullPurchaseOnGtag={
                needToSendSuccessFullPurchaseOnGtag
              }
              showDataFromLocal={needToShowDataLocally}
            />
          </div>
        )}
        {screen == 2 && (
          <div>
            <MatchBoxScreen
              user={userProps}
              matchBoxButtonClicked={() => this.performMatchBoxAction()}
            />
          </div>
        )}
      </ScreenContainer>
    );
  }
}

const mapStateToProps = ({ remote }) => {
  const {
    isLoadingData,
    discover,
    visitor,
    likedMe,
    checksumData,
    userProfile,
  } = remote;
  return {
    discover,
    visitor,
    likedMe,
    isLoadingData,
    checksumData,
    userProfile,
  };
};

const styledComponent = withRoot(withStyles(styles)(DiscoverScreen));
const connectedComponent = connect(mapStateToProps, {
  appLaunch,
  runDiscover,
  callDiscover,
  saveFirebaseToken,
  generateCheckSum,
  purchaseProduct,
  logout,
})(styledComponent);
export { connectedComponent as DiscoverScreen };
