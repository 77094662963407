import React, { useEffect } from "react";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

// A theme with custom primary and secondary color.
// It's optional.

const theme = createMuiTheme({
  palette: {
    type: "light",
    primary: {
      main: localStorage.getItem("skin") ? JSON.parse(localStorage.getItem("skin")).primaryColor : "#482683",
      dark: localStorage.getItem("skin") ? JSON.parse(localStorage.getItem("skin")).primaryColor : "#482683",
      medium: localStorage.getItem("skin") ? JSON.parse(localStorage.getItem("skin")).primaryColor : "#482683",
    },
    secondary: {
      main: "#FFF"
    },
    error: {
      main: "#ff6e00"
    },
    common: {
      white: "#ffffff"
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    grey: {
      light: '#cfcfcf',
      medium: '#f0f2f9',
      dark: '#e8ebf6',
    },
    blue: {
      light: '#0083ff',
    },
    font: {
      color: {
        dark: "#323952",
        perrywinkle: '#9275db',
      },
      size: {
        heading: 18,
        normal: 16,
        small: 14,
        xsmall: 12,
        large: 18,
        xxxlarge: 40,
      },
      family: {
        main: 'Lato',
      },
      weight: {
        xxxdark: 900,
        xdark: 600,
      }
    }
  },
  overrides: {
    MuiButton: {
      containedPrimary: {
        color: "white"
      }
    }
  },
  typography: {
    fontSize: 14,
    fontFamily: "Lato",
  },
  customBreakPoints: {
    xxs: 375,
  }
});

function withRoot(Component) {
  function WithRoot(props) {
    
    useEffect(() => {
      document.body.style.backgroundColor = theme.palette.grey.medium;
    }, []);

    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Component {...props} />
      </MuiThemeProvider>
    );
  }

  return WithRoot;
}

export default withRoot;
