const styles = (theme) =>({
  overlay: {
    position: 'absolute',
    zIndex: 3,
    width: '100%',
    maxWidth: 500,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  }
})
  
export default styles;