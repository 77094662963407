import React from 'react'
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';

import withRoot from '../../withRoot';
import styles from './style';

const Loading = (props) => {
  const { isOverlay = true, classes, url } = props;
  const loadingClasses = classNames({
    [classes.overlay] : isOverlay,
  })
 
  return (
    <div className={loadingClasses}>
      {url ? <img
        style={{
          width: "70px",
          height: "50px",
          position: "fixed",
          top: "50%"
        }}
        // src="images/loader.gif"
        // src="http://d2pd5v9y7ukdgh.cloudfront.net/Chatezee/chatezee.png"
        src={url}
      /> : <p>Loading...</p>}
     
    </div>
  );
}

const styledComponent = withRoot(withStyles(styles)(Loading));
export default styledComponent;