export {HomePage} from './HomePage/HomePage';
export {UserHome} from './UserHome/UserHome'
import WebFont from 'webfontloader';


WebFont.load({
  google: {
    families: ['Lato:300,400,700']
  }
});
