import React from "react";
import ReactDOM from "react-dom";
import { Provider, connect } from "react-redux";
import { Router, Route, Switch } from "react-router-dom";
import { store, history } from "./helpers";
import { PrivateRoute } from "./components";
import { toast } from "react-toastify";

// import "./views";

import "react-toastify/dist/ReactToastify.css";
import "./assets/index.min.css";

import loadable from "@loadable/component";
import smartlookClient from "smartlook-client";

import { UserHome } from "./views";
// import { HomePage } from "./views/HomePage/HomePage";
// import { PhoneVerifyTakeNumberScreen } from "./views/PhoneVerfiyScreens/PhoneVerifyTakeNumberScreen/PhoneVerifyTakeNumberScreen";
import { getCookie } from "./helpers/cookie";
import { TELCO_LOGIN_FAIL_URL, DOMAIN } from "./config";
import { enLanguage, frLanguage,arLanguage,zuLanguage } from "./langData";

const HomePage = loadable(() => import("./views/HomePage"));
const DiscoverScreen = loadable(() => import("./views/DiscoverScreen"));
const ProfileDetailScreen = loadable(() =>
  import("./views/ProfileDetailScreen")
);
const PhoneVerifyTakeNumberScreen = loadable(() =>
  import("./views/PhoneVerfiyScreens/PhoneVerifyTakeNumberScreen")
);
const NameScreen = loadable(() => import("./views/NameScreen"));
const DOBScreen = loadable(() => import("./views/DOBScreen"));
const UploadPhotoScreen = loadable(() => import("./views/UploadPhotoScreen"));
const ShowPhotoScreen = loadable(() => import("./views/ShowPhotoScreen"));
const AboutMe = loadable(() => import("./views/AboutMe"));
const Interests = loadable(() => import("./views/MyInterests"));

const LocationPermissionScreen = loadable(() =>
  import("./views/LocationPermissionScreen")
);
const GenderSelectionScreen = loadable(() =>
  import("./views/GenderSelectionScreen")
);
const PhoneVerifySendOtpScreen = loadable(() =>
  import("./views/PhoneVerfiyScreens/PhoneVerifySendOtpScreen")
);
const PaymentCallBackScreen = loadable(() =>
  import("./views/PaymentCallBackScreen")
);
const MeInnerScreen = loadable(() => import("./views/MeScreen/MeInnerScreens"));
const CrushReceived = loadable(() => import("./views/CrushScreen"));
const SettingsScreen = loadable(() => import("./views/SettingsScreen"));
const SubscriptionScreen = loadable(() =>
  import("./views/SubscriptionScreen/SubscriptionScreen")
);

const ConfirmSignout = loadable(() =>
  import("./views/ConfirmSignout")
);

const EditProfile = loadable(() => import("./views/EditProfile"));
const PhotoTip = loadable(() => import("./views/PhotoTip"));
const ChatScreen = loadable(() => import("./views/Chat"));
const Feedback = loadable(() => import("./views/Feedback/Feedback"));
const MyPurchase = loadable(() => import("./views/MyPurchase/MyPurchase"));
// const UserHome = loadable(() => import('./views/UserHome'));
const BlockerScreen = loadable(() => import("./views/UserHome"));
// const PrivacyPolicy = loadable(() =>
//   import("./views/PrivacyPolicy/PrivacyPolicy")
// );
const FrPrivacyPolicy = loadable(() =>
  import("./views/PrivacyPolicy/sn_EPSN_fr_PrivacyPolicy")
);

class App extends React.Component {
  constructor(props) {
    super(props);
    const mainHeight = window.innerHeight;
    window.addEventListener("resize", function () {
      if (
        document.activeElement.tagName == "INPUT" ||
        document.activeElement.tagName == "TEXTAREA"
      ) {
        window.setTimeout(function () {
          const moveUpElement = document.getElementsByClassName("move-up");
          window.innerHeight !== mainHeight &&
            moveUpElement &&
            moveUpElement[0] &&
            (moveUpElement[0].style.marginTop = "-37%");
        }, 0);
      }
      if (window.innerHeight === mainHeight) {
        const moveUpElement = document.getElementsByClassName("move-up");
        moveUpElement &&
          moveUpElement[0] &&
          (moveUpElement[0].style.marginTop = "0");
      }
    });
  }

  componentDidMount() {
    smartlookClient.init("1cf6e095495bdee3afc982df0668befb00e3325f");

    let countryId = getCookie("X-Reycreo-Country-Id");
    let authToken = getCookie("X-Reycreo-Auth-Token");
    let id = getCookie("X-Reycreo-Id");
    let languageId = getCookie("X-Reycreo-Language-Id");
    let operatorId = getCookie("X-Reycreo-Operator-Id");

     const skinTitle=localStorage.getItem("skinTitle")
       document.title=skinTitle == null ? "" : skinTitle;
    

    if (!(countryId && authToken && id && languageId && operatorId)) {
      console.log("Cookies not available");
      const langCode = localStorage.getItem("ukuLangCode");

      // if (langCode === "fr") {
      //   document.cookie =
      //     `X-Reycreo-Language-Id=fr; domain=.${DOMAIN}; path=/`;
      // } else {
      //   document.cookie =
      //     `X-Reycreo-Language-Id=en; domain=.${DOMAIN}; path=/`;
      // }
      `X-Reycreo-Language-Id=fr; domain=.${DOMAIN}; path=/`;
      console.log("index login url", TELCO_LOGIN_FAIL_URL)
      // window.location = TELCO_LOGIN_FAIL_URL;
      window.location.replace(TELCO_LOGIN_FAIL_URL)
    } else {
      console.log("Cookies available");
    }

    let langData;
    let langCode;
    if (languageId === "en") {
      langData = enLanguage;
      langCode = "en";
    }
    else if(languageId === "ar"){
      langData = arLanguage;
      langCode = "ar";
    }
    else if(languageId === "zu"){
      langData = zuLanguage;
      langCode = "zu";
    }
     else {
      langData = frLanguage;
      langCode = "fr";
    }
    let user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      if (user["language"]) {
        console.log("user language set", user["language"]);
        langCode = user["language"];
      }
    }

    const jsonLangData = JSON.stringify(langData);
    if (!localStorage.getItem("ukuLang")) {
      localStorage.setItem("ukuLang", jsonLangData);
    }
    if (!localStorage.getItem("ukuLangCode")) {
      localStorage.setItem("ukuLangCode", langCode);
    }
    console.log("index env",env,process.env,process.env.REACT_APP_SERVER_URL)
    if(countryId && authToken && id && languageId && operatorId){
      // fetch(`http://staging.manakyow.com/skinTheam?operatorId=${operatorId}&domain=${window.location.host}`)
      // .then(response => console.log("response",response))
      // .catch(error=>console.log("error",error))
      // console.log("break and try")
      this.getAppAssets(operatorId)
    }
  }

  getAppAssets = async (operatorId) => {
    const response = await fetch(`http://${window.location.host}/skinTheam?operatorId=${operatorId}&domain=${window.location.host}`)
    // const response = await fetch(`http://u2opia.stagingreycreo.com/skinTheam?operatorId=U2OPIADUMMY&domain=u2opia.stagingreycreo.com`)

    console.log("response",response)
    const res = await response.json()
    
    console.log("res",res)
    localStorage.setItem("skin", JSON.stringify(res))
    document.title = res.theme.name;
    const favIconElement = document.getElementById("favicon");
    favIconElement.href = res.theme.favicon
  }

  render() {
    toast.configure({
      autoClose: 2000,
      draggable: false,
      hideProgressBar: true,
      //etc you get the idea
    });

    return (
      <Router history={history} basename={"/luvingtales"}>
        <Switch>
          <Route path="/login*" component={HomePage} />
          <Route path="/home" component={HomePage} />
          <PrivateRoute path="/nameScreen" component={NameScreen} />
          <PrivateRoute path="/dobScreen" component={DOBScreen} />
          <PrivateRoute path="/uploadPhoto" component={UploadPhotoScreen} />
          <PrivateRoute path="/showPhoto" component={ShowPhotoScreen} />
          <PrivateRoute path="/aboutme" component={AboutMe} />
          <PrivateRoute path="/interests" component={Interests} />


          <PrivateRoute path="/location" component={LocationPermissionScreen} />
          <PrivateRoute
            path="/selectGender"
            component={GenderSelectionScreen}
          />
          <Route path="/discoverScreen" component={DiscoverScreen} />
          <Route path="/profileDetailScreen" component={ProfileDetailScreen} />
          <Route path="/phoneVerify" component={PhoneVerifyTakeNumberScreen} />
          <Route path="/OTP" component={PhoneVerifySendOtpScreen} />
          <Route path="/paymentCallback" component={PaymentCallBackScreen} />
          <Route path="/meInnerScreen" component={MeInnerScreen} />
          <Route path="/crushRecieved" component={CrushReceived} />
          <Route path="/settings" component={SettingsScreen} />
          <Route path="/subscriptions" component={SubscriptionScreen} />
          <Route path="/confirmSignout" component={ConfirmSignout} />

          <Route path="/mypurchases" component={MyPurchase} />
          <Route path="/feedback" component={Feedback} />
          <Route path="/editProfile" component={EditProfile} />
          <Route path="/photoTip" component={PhotoTip} />
          <Route path="/chat" component={ChatScreen} />

          <PrivateRoute path="/blocker" component={BlockerScreen} />
          {/* <Route path="/en_privacyPolicy" component={PrivacyPolicy} /> */}
          <Route path="/sn_EPSN_fr_privacyPolicy" component={FrPrivacyPolicy} />
          <PrivateRoute path="/" component={UserHome} />
          {/* <Route path="/" component={() => {return <h1>Home page</h1>}} />
					<Route path="/test-page" component={() => {return <h1>Test Page</h1>}} />
					<Route path="/*" component={() => {return <h1>Not Found</h1>}} /> */}
        </Switch>
      </Router>
    );
  }
}

function mapStateToProps(state) {
  const { alert } = state;
  return {
    alert,
  };
}

const ConnectedApp = connect(mapStateToProps)(App);
if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./firebase-messaging-sw.js")
    .then(function (registration) {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch(function (err) {
      console.log("Service worker registration failed, error:", err);
    });
}
ReactDOM.render(
  <Provider store={store}>
    <ConnectedApp />
  </Provider>,
  document.getElementById("root")
);
