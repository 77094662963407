import Api from "../helpers/api"
import {
  PHOTO_UPLOAD,
  SET_PHOTO_DATA,
  PHOTO_UPLOAD_CHAT,
  SET_CHAT_PHOTO_DATA,
  CLEAR_ALBUM,
  SET_DISCOVER_DATA,
  SET_DISCOVER_DATA_PAGINATION,
  DO_DISCOVER,
  SET_VISITOR_DATA,
  GET_VISITOR,
  CHECKSUM_GENERATED,
  GET_USER_PROFILE,
  USER_PROFILE,
  SET_LIKEDME_DATA,
  GET_LIKEDME,
  SET_SKIPPED_DATA,
  GET_SKIPPED_DATA,
  SET_LIKEDBYME_DATA,
  GET_LIKEDBYME_DATA,
  SET_CRUSHES_DATA,
  GET_CRUSHES_DATA,
  LIKE_USER,
  DISLIKE_USER,
  SET_LOADING,
  GET_MATCHES,
} from "./types"
import { history } from "../helpers/history"
import { APP_VERSION, TELCO_LOGIN_FAIL_URL, DOMAIN } from "../config"
//import { trackEventForUser } from "../helpers/MixpanelTracker"

import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export function sendMessage(user, message) {
  return Api.apiAction({
    url: `/v2/feedback?wooId=${user["wooId"]}`,
    data: { feedBackText: message },
    method: "POST",
  })
}

export function uploadPhoto(user, formData, replaceId) {
  let url = `/v3/user/uploadPhoto?wooId=${user["wooId"]}`
  console.log(replaceId + "##")
  if (replaceId) {
    url = `/v3/user/uploadPhoto?wooId=${user["wooId"]}&replaceObjectId=${replaceId}`
  }
  return Api.apiAction({
    url: url,
    data: formData,
    multipart: true,
    onSuccess: setData,
    label: PHOTO_UPLOAD,
    method: "POST",
    handleLargeNumber: true,
  })
}

export function uploadPhotoToChat(user, formData) {
  const url = `/v1/user/uploadPhoto?wooId=${user["wooId"]}`
  return Api.apiAction({
    url: url,
    data: formData,
    multipart: true,
    onSuccess: setChatPhotoData,
    label: PHOTO_UPLOAD_CHAT,
    method: "POST",
    handleLargeNumber: true,
  })
}

function setChatPhotoData(data) {
  return (dispatch) => {
    dispatch({ type: SET_CHAT_PHOTO_DATA, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export function getUserProfile(user, wooID) {
  let url = `/v14/user/activity/profile/?wooUserId=${user["wooId"]}&targetId=${wooID}`
  return Api.apiAction({
    url: url,
    onSuccess: setUserProfileData,
    label: USER_PROFILE,
    method: "GET",
  })
}

export function updateUserProfile(user, payload) {
  let url = `/v16/user/activity/profile?wooUserId=${
    user["wooId"]
  }&payload=${encodeURI(JSON.stringify(payload))}`
  return Api.apiAction({
    url: url,
    // onSuccess: setUserProfileData,
    // label: USER_PROFILE,
    method: "POST",
    callback: updateUserProfileCallback,
    failureCallback: updateUserProfileFailureCallback,
  })
}

function updateUserProfileCallback() {
  console.log("userprofile updated")
  history.push({ pathname: "/", state: { userTagsSelected: true } })
}

function updateUserProfileFailureCallback() {
  alert("update user profile failed")
}

function setUserProfileData(data) {
  return (dispatch) => {
    dispatch({ type: GET_USER_PROFILE, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export function getMatches(user) {
  let url = `/v3/${user["wooId"]}/matches?time=0`
  return Api.apiAction({
    url: url,
    onSuccess: setMatchesData,
    label: GET_MATCHES,
    method: "GET",
  })
}

function setMatchesData(data) {
  return {
    type: GET_MATCHES,
    payload: data,
  }
}

export function likeUser(user, wooID, crushMessage) {
  let url = ""
  if (crushMessage) {
    url = `/v4/user/activity/like/?actorId=${user["wooId"]}&targetId=${wooID}&text=${crushMessage}&crush=TRUE&actorPurchasedCrush=TRUE`
  } else {
    url = `/v4/user/activity/like/?actorId=${user["wooId"]}&targetId=${wooID}`
  }

  return Api.apiAction({
    url: url,
    label: LIKE_USER,
    method: "POST",
    onSuccess: setLikeSuccessData,
    //statusCodeCallBack: likeUserCallBack
  })
}

function setLikeSuccessData(data) {
  return {
    type: LIKE_USER,
    payload: data,
  }
}

function likeUserCallBack(statusCode) {
  console.log("statusCode", statusCode)
  return {
    type: LIKE_USER,
    payload: statusCode,
  }
}

function logoutSuccess(status) {
  console.log("logout success fired")
  const ukuLangCode = localStorage.getItem("ukuLangCode")
  const ukuLang = localStorage.getItem("ukuLang")
  localStorage.clear()
  sessionStorage.clear()
  ;(function () {
    var cookies = document.cookie.split("; ")
    for (var c = 0; c < cookies.length; c++) {
      var d = window.location.hostname.split(".")
      while (d.length > 0) {
        var cookieBase =
          encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
          "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
          d.join(".") +
          " ;path="
        var p = location.pathname.split("/")
        document.cookie = cookieBase + "/"
        while (p.length > 0) {
          document.cookie = cookieBase + p.join("/")
          p.pop()
        }
        d.shift()
      }
    }
  })()
  localStorage.setItem("ukuLangCode", ukuLangCode)
  localStorage.setItem("ukuLang", ukuLang)
  // history.push("/");
  // window.location = TELCO_LOGIN_FAIL_URL
  window.location.replace(TELCO_LOGIN_FAIL_URL)
  //  setTimeout(() => {
  //   history.push("/")
  // },2000);
}
export function logout(userObj) {
  console.log("logout api fired")
  let url = `/v2/user/activity/logout/?wooId=${userObj.wooId}&wooToken=${userObj.loginDto.wooToken}`
  console.log("logut url " + url)
  return Api.apiAction({
    url: url,
    method: "POST",
    onSuccess: "",
    statusCodeCallBack: logoutSuccess,
  })
}

export function disLikeUser(user, wooID, subSource) {
  let url = `/v3/user/activity/disLike/?actorId=${user["wooId"]}&targetId=${wooID}&subSource=${subSource}`
  return Api.apiAction({
    url: url,
    label: DISLIKE_USER,
    method: "POST",
    onSuccess: setDislikeSuccessData,
    //statusCodeCallBack: disLikeUserCallBack
  })
}

function setDislikeSuccessData(data) {
  return {
    type: DISLIKE_USER,
    payload: data,
  }
}

function disLikeUserCallBack(statusCode) {
  console.log("statusCode", statusCode)
  return
  return {
    type: DISLIKE_USER,
    payload: statusCode,
  }
}

export function appLaunch(user) {
  return Api.apiAction({
    url: `/v6/appLaunch?wooId=${user["wooId"]}`,
    method: "POST",
    callback: appLaunchCallback,
    failureCallback: appLaunchFailureCallback,
  })
}

function appLaunchCallback(data) {
  let paidUser = false
  let hasPurchasedCrush = false
  let leftCrushes = 0
  let totalCrushes = 0
  if (data["productDto"]) {
    const productDto = data["productDto"]
    const wooPlusData = productDto["wooPlusDto"]
    paidUser = !wooPlusData["expired"]

    const crushData = productDto["crushDto"]
    hasPurchasedCrush = crushData["hasPurchased"]
    leftCrushes = crushData["availableCrush"]
    totalCrushes = crushData["totalCrush"]
  }
  // if (data.productDto.wooPlusDto["paymentSettingsDto"]) {
  //   console.log("paymentSettingsDto");
  // }
  if (data["doNotShowPurchaseScreen"]) {
    console.log("Do not show purchase screen")
  }
  const paymentSettingsDto = data.productDto.wooPlusDto["paymentSettingsDto"]
  if (paymentSettingsDto) {
    console.log("paymentSettingsDto", paymentSettingsDto)
    const subscriptionDueDate = paymentSettingsDto.epochEndTime
    const subscriptionStatus = paymentSettingsDto.currentState
    console.log("subscriptionDueDate", subscriptionDueDate)
    localStorage.setItem("subscriptionDueDate", subscriptionDueDate)
    localStorage.setItem("subscriptionStatus", subscriptionStatus)
  }
  if (data["redirectBillingURL"]) {
    const langCode = localStorage.getItem("ukuLangCode")
    // if (langCode === "en") {
    //   document.cookie = `X-Reycreo-Language-Id=en; domain=.${DOMAIN}; path=/`
    // } else {
    //   document.cookie = `X-Reycreo-Language-Id=fr; domain=.${DOMAIN}; path=/`
    // }
    document.cookie = `X-Reycreo-Language-Id=${langCode}; domain=.${DOMAIN}; path=/`
    window.location = data["redirectBillingURL"]
  }
  if (data["billingErrorMessage"]) {
    const errMsg = data["billingErrorMessage"]
    localStorage.setItem("billingErrorMessage", errMsg)
    // alert(errMsg);
    // const user = localStorage.getItem("user");
    //   let userObj = user ? JSON.parse(user) : null;
    //   console.log("billingErrorMessage 1");
    //   return dispatch => dispatch(logout(userObj));
  }

  // if (data.wooPlusDto.paymentSettingsDto) {
  // 	alert("data.wooPlusDto.paymentSettingsDto");
  //   console.log("dueDate", data.wooPlusDto.paymentSettingsDto)
  //   const subscriptionDueDate = data.wooPlusDto.paymentSettingsDto;
  //   localStorage.setItem("subscriptionDueDate", subscriptionDueDate);
  // }
  console.log("paidUser", paidUser)
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    apLaunchData: data,
    isPaidUser: paidUser,
    crushesLeft: leftCrushes,
    totalCrushes: totalCrushes,
  }
  console.log("data in applaunch", data)
  localStorage.setItem("user", JSON.stringify(user))
}

function appLaunchFailureCallback(status) {
  console.log("appLaunchFailureCallback 1")
  if (status != 200) {
    // alert("AppLaunch Failed with status " + status);
    const user = localStorage.getItem("user")
    let userObj = user ? JSON.parse(user) : null
    console.log("appLaunchFailureCallback 2")
    logout(userObj)
    console.log("appLaunchFailureCallback 3")
  }
}

function getCrushTemplates(user) {
  return Api.apiAction({
    url: `/v8/app/config/sync?wooId=${user["wooId"]}&syncType=CRUSH_TEMPLATES`,
    method: "GET",
    callback: getCrushTemplatesCallback,
  })
}

function getCrushTemplatesCallback(data) {
  console.log("getCrushTemplatesCallback", data)
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    crushTemplates: user["crushTemplates"],
  }
  console.log("data in crushTemplates", data)
  localStorage.setItem("user", JSON.stringify(user))
}

export function saveFirebaseToken(user, fireBaseToken) {
  return Api.apiAction({
    url: `/v1/save/firebaseToken?wooId=${user["wooId"]}&token=${fireBaseToken}`,
    method: "POST",
    callback: fireBaseCallback,
  })
}

function fireBaseCallback(data) {
  console.log("firsbase data", data)
}

let globalPaymentPayLoad = null
let globalCurrentWooProduct = null

export function purchaseProduct(
  user,
  paymentPayload,
  isPaytm,
  currentWooProduct
) {
  console.log("notHere", paymentPayload)
  globalPaymentPayLoad = paymentPayload
  globalCurrentWooProduct = currentWooProduct
  let paymenturl = ""
  if (isPaytm === true) {
    paymenturl = `/v12/user/product/purchase/?wooId=${user["wooId"]}&productType=${paymentPayload["productType"]}&planId=${paymentPayload["planId"]}&transactionId=${paymentPayload["transactionId"]}&orderId=${paymentPayload["orderId"]}&purchaseChannel=${paymentPayload["purchaseChannel"]}&payload=${paymentPayload["payload"]}&nudgeDiscountFlag=${paymentPayload["nudgeDiscountFlag"]}`
    if (paymentPayload.hasOwnProperty("purchaseToken")) {
      paymenturl = `/v12/user/product/purchase/?wooId=${user["wooId"]}&productType=${paymentPayload["productType"]}&planId=${paymentPayload["planId"]}&transactionId=${paymentPayload["transactionId"]}&orderId=${paymentPayload["orderId"]}&purchaseChannel=${paymentPayload["purchaseChannel"]}&payload=${paymentPayload["payload"]}&nudgeDiscountFlag=${paymentPayload["nudgeDiscountFlag"]}&purchaseToken=${paymentPayload["purchaseToken"]}`
    }
  } else {
    paymenturl = `/v12/user/product/purchase/?wooId=${user["wooId"]}&productType=${paymentPayload["productType"]}&planId=${paymentPayload["planId"]}&purchaseChannel=${paymentPayload["purchaseChannel"]}&nudgeDiscountFlag=${paymentPayload["nudgeDiscountFlag"]}&purchaseToken=${paymentPayload["purchaseToken"]}`
  }
  console.log("paytmurl for purchasing product", paymenturl)
  return Api.apiAction({
    url: paymenturl,
    method: "POST",
    callback: productPurchasedCallback,
  })
}

function productPurchasedCallback(data) {
  console.log("productPurchasedCallback data", data)
  // console.log("callbackCheck", paymentPayload);
  let user = JSON.parse(localStorage.getItem("user"))
  if (data["wooPlusDto"]) {
    let paidUser = false
    const wooPlusPurchasedDto = data["wooPlusDto"]
    paidUser = !wooPlusPurchasedDto["expired"]
    user = {
      ...user,
      isPaidUser: paidUser,
    }
    localStorage.setItem("user", JSON.stringify(user))
    toast("WooPlus Activated...")
  } else {
    if (data["crushDto"]) {
      const crushData = data["crushDto"]
      user = {
        ...user,
        crushesLeft: crushData["availableCrush"],
        totalCrushes: crushData["totalCrush"],
      }
      localStorage.setItem("user", JSON.stringify(user))
      toast("Crush Received...")
    }
  }
  //trackEventForUser("Purchase_Success")
  localStorage.setItem("user", JSON.stringify(user))

  console.log("globalPaymentPayLoad", globalPaymentPayLoad)
  console.log("globalCurrentWooProduct", globalCurrentWooProduct)

  console.log(
    "user['purchaseInitiatedScreen']",
    user,
    user["purchaseInitiatedScreen"]
  )
  // if (user['purchaseInitiatedScreen'] !== null){
  if (user["purchaseInitiatedScreen"]) {
    if (user["purchaseInitiatedScreen"] == "DISCOVER") {
      history.replace({
        pathname: "/discoverScreen",
        state: {
          settingsChanged: false,
          showVisitor: false,
          needToSendSuccessFullPurchaseOnGtag: true,
        },
      })
    } else if (user["purchaseInitiatedScreen"] == "USERPROFILE") {
      history.replace({
        pathname: "/profileDetailScreen",
        state: {
          type: user["comingFroViewType"],
          needToSendSuccessFullPurchaseOnGtag: true,
        },
      })
    } else {
      history.replace({
        pathname: "/meInnerScreen",
        state: {
          screenType: user["purchaseInitiatedScreen"],
          needToSendSuccessFullPurchaseOnGtag: true,
        },
      })
    }
  } else {
    history.replace({
      pathname: "/",
      state: {
        settingsChanged: false,
        showVisitor: false,
        needToSendSuccessFullPurchaseOnGtag: true,
      },
    })
  }
  user = {
    ...user,
    comingFroViewType: null,
  }
  localStorage.setItem("user", JSON.stringify(user))
}

export function sendFeedBack(user, feedbackText, emailID, phoneNumber) {
  return Api.apiAction({
    url: `/v2/feedback?wooId=${user["wooId"]}&feedBackText=${feedbackText}&email=${emailID}&phoneNumer=${phoneNumber}`,
    method: "POST",
    data: {},
  })
}

export function generateCheckSum(user, paytmPayload) {
  return Api.apiAction({
    url: `/v1/checksum/generate?wooId=${user["wooId"]}`,
    method: "POST",
    data: paytmPayload,
    onSuccess: checksumGenerated,
    callback: checksumGeneratedCallback,
    // failureCallback : () => {console.log("checksum failed")},
  })
}

function checksumGenerated(data) {
  return {
    type: CHECKSUM_GENERATED,
    payload: data,
  }
}

function checksumGeneratedCallback(data) {
  console.log("checksum data", data)
}

function getProducts(user) {
  console.log("getProducts", getProducts)
  return Api.apiAction({
    url: `/v12/getProduct/new/${user["wooId"]}`,
    method: "GET",
    callback: getProductsCallback,
  })
}

function getProductsCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  const wooPlusData = data["wooPlusDto"]
  const crushData = data["crushDto"]
  console.log("product data", data)
  if (data) {
    user = {
      ...user,
      wooPlusDto: wooPlusData,
      crushDto: crushData,
    }
  }
  localStorage.setItem("user", JSON.stringify(user))
}

export function runDiscover(user) {
  console.log("user in runDiscover", user)
  return Api.apiAction({
    url: `/v3/user/preferences?wooId=${user["wooId"]}`,
    method: "GET",
    onSuccess: preferencesCallback,
  })
}

export function getVisitor(user) {
  const visitorFetchedTillTime = user.visitorFetchedTillTime
  let visitorLastUpdatedTime = user.apLaunchData
    ? user.apLaunchData.visitorLastUpdatedTime
    : 0
  visitorLastUpdatedTime = 0
  if (
    visitorFetchedTillTime == null ||
    visitorLastUpdatedTime == 0 ||
    visitorFetchedTillTime != visitorLastUpdatedTime
  ) {
    return Api.apiAction({
      url: `/v8/dashboard/visitor?wooId=${user["wooId"]}&time=${visitorLastUpdatedTime}`,
      method: "GET",
      label: GET_VISITOR,
      onSuccess: setVisitorData,
      callback: visitorCallback,
      onFail: setLoading.bind(null, false),
    })
  }
}

function visitorCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    visitorFetchedTillTime: data.time,
    visitor: data,
  }
  localStorage.setItem("user", JSON.stringify(user))
}

export function getLikedMe(user) {
  const likedMeFetchedTillTime = user.likedMeFetchedTillTime
  let likedMeLastUpdatedTime = user.apLaunchData
    ? user.apLaunchData.likedMeLastUpdatedTime
    : 0
  likedMeLastUpdatedTime = 0
  if (
    likedMeFetchedTillTime == null ||
    likedMeLastUpdatedTime == 0 ||
    likedMeFetchedTillTime != likedMeLastUpdatedTime
  ) {
    return Api.apiAction({
      url: `/v8/dashboard/likedMe?wooId=${user["wooId"]}&time=${likedMeLastUpdatedTime}`,
      method: "GET",
      label: GET_LIKEDME,
      onSuccess: setLikedMeData,
      callback: likedMeCallback,
      onFail: setLoading.bind(null, false),
    })
  }
}

function likedMeCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    likedMeFetchedTillTime: data.time,
    likedMe: data,
  }
  localStorage.setItem("user", JSON.stringify(user))
}

function setLikedMeData(data) {
  return (dispatch) => {
    dispatch({ type: SET_LIKEDME_DATA, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export function setLoading(data) {
  return {
    type: SET_LOADING,
    payload: data,
  }
}

export function getSkippedProfiles(user) {
  const skippedProfilesFetchedTillTime = user.skippedProfilesFetchedTillTime
  let skippedProfilesLastUpdatedTime = user.apLaunchData
    ? user.apLaunchData.skippedProfilesLastUpdatedTime
    : 0
  skippedProfilesLastUpdatedTime = 0
  if (
    skippedProfilesFetchedTillTime == null ||
    skippedProfilesLastUpdatedTime == 0 ||
    skippedProfilesFetchedTillTime != skippedProfilesLastUpdatedTime
  ) {
    return Api.apiAction({
      url: `/v8/dashboard/skippedProfiles?wooId=${user["wooId"]}&time=${skippedProfilesLastUpdatedTime}`,
      method: "GET",
      label: GET_SKIPPED_DATA,
      onSuccess: setSkippedProfilesData,
      callback: skippedProfilesCallback,
      onFail: setLoading.bind(null, false),
    })
  }
}

function skippedProfilesCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    skippedProfilesFetchedTillTime: data.time,
    skippedProfiles: data,
  }
  localStorage.setItem("user", JSON.stringify(user))
}

function setSkippedProfilesData(data) {
  return (dispatch) => {
    dispatch({ type: SET_SKIPPED_DATA, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export function getCrushes(user) {
  const crushesFetchedTillTime = user.crushesFetchedTillTime
  let crushLastUpdatedTime = user.apLaunchData
    ? user.apLaunchData.crushLastUpdatedTime
    : 0
  crushLastUpdatedTime = 0
  if (
    crushesFetchedTillTime == null ||
    crushLastUpdatedTime == 0 ||
    crushesFetchedTillTime != crushLastUpdatedTime
  ) {
    return Api.apiAction({
      url: `/v7/dashboard/crush?wooId=${user["wooId"]}&time=${crushLastUpdatedTime}`,
      method: "GET",
      label: GET_CRUSHES_DATA,
      onSuccess: setCrushesData,
      callback: crushesCallback,
    })
  }
}

function crushesCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    crushesFetchedTillTime: data.time,
    crushes: data,
  }
  localStorage.setItem("user", JSON.stringify(user))
}

function setCrushesData(data) {
  return {
    type: SET_CRUSHES_DATA,
    payload: data,
  }
}

export function getLikedByMe(user) {
  //const likedByMeFetchedTillTime = user.likedByMeFetchedTillTime
  //let likedByMeLastUpdatedTime = user.apLaunchData ? user.apLaunchData.likedByMeLastUpdatedTime :0
  //likedByMeLastUpdatedTime = 0
  return Api.apiAction({
    url: `/v8/dashboard/likedByMe?wooId=${user["wooId"]}&time=0`,
    method: "GET",
    label: GET_LIKEDBYME_DATA,
    onSuccess: setLikedByMeData,
    callback: likedByMeCallback,
    onFail: setLoading.bind(null, false),
  })
}

function likedByMeCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    likedByMeFetchedTillTime: data.time,
    likedByMe: data,
  }
  localStorage.setItem("user", JSON.stringify(user))
}

function setLikedByMeData(data) {
  return (dispatch) => {
    dispatch({ type: SET_LIKEDBYME_DATA, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

function preferencesCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    preferences: data,
    minAge: data["minAge"],
    maxAge: data["maxAge"],
    language: data["language"],
    interestedGender: data["interestedGender"],
    maxDistance: data["maxDistance"],
    location: data["location"] ? data["location"] : "Gurgaon",
  }

  localStorage.setItem("user", JSON.stringify(user))
  return (dispatch) => {
    dispatch(callDiscover(user))
    dispatch(getCrushTemplates(user))
  }
}

export function callDiscover(user, discoverData, makePagination) {
  console.log("user Value", user)
  console.log("discover Value", discoverData)
  console.log("preferences", user["preferences"])
  let minimumAge = 18
  if (user["minAge"]) {
    minimumAge = user["minAge"]
  }
  let maximumAge = 65
  if (user["maxAge"]) {
    maximumAge = user["maxAge"]
  }
  var indexValue = 0
  if (discoverData && discoverData["index"]) {
    indexValue = discoverData["index"]
  }
  let latitudeValue = 0.0
  let longitudeValue = 0.0
  let iskms = false
  if (user["latitude"]) {
    latitudeValue = user["latitude"]
  } else if (user["loginDto"]["latitude"]) {
    latitudeValue = user["loginDto"]["latitude"]
  }

  if (user["longitude"]) {
    longitudeValue = user["longitude"]
  } else if (user["loginDto"]["longitude"]) {
    longitudeValue = user["loginDto"]["longitude"]
  }

  if (user["preferences"]) {
    const preferences = user["preferences"]
    if (preferences["kms"]) {
      iskms = preferences["kms"]
    }
  }

  let cityValue = "Gurgaon"
  if (user.hasOwnProperty("selectedLocation")) {
    cityValue = user["selectedLocation"]
  } else {
    if (user.hasOwnProperty("location")) {
      cityValue = user["location"]
    }
  }

  let userLangCode = "en"
  if (localStorage.getItem("ukuLangCode")) {
    userLangCode = localStorage.getItem("ukuLangCode")
  }

  let query = {
    wooId: user["wooId"],
    latitude: latitudeValue,
    longitude: longitudeValue,
    city: cityValue,
    appVersion: APP_VERSION,
    deviceId: localStorage.getItem("deviceId"),
    gmtTime: 19800,
    minAge: minimumAge,
    maxAge: maximumAge,
    interestedGender: user["interestedGender"],
    maxDistance: user["maxDistance"],
    isKms: iskms,
    showLocation: user["preferences"]["showLocation"],
    profileVisible: user["preferences"]["profileVisible"],
    index: indexValue,
    forDiscoverEmpty: false,
    locationChanged: false,
    language: userLangCode,
  }
  if (
    discoverData &&
    discoverData["paginationToken"] &&
    makePagination == true
  ) {
    query = {
      ...query,
      paginationToken: discoverData["paginationToken"],
    }
  }

  const queryString = getQueryString(query)
  console.log("queryString", queryString)
  const url = `/v21/user/activity/discover?${queryString}`
  return Api.apiAction({
    url: url,
    method: "POST",
    data: {},
    onSuccess: makePagination
      ? setDiscoverDataForPaginationCall
      : setDiscoverData,
    label: DO_DISCOVER,
  })
}

function setVisitorData(data) {
  return (dispatch) => {
    dispatch({ type: SET_VISITOR_DATA, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

function setDiscoverData(data) {
  let user = JSON.parse(localStorage.getItem("user"))

  return (dispatch) => {
    dispatch({ type: SET_DISCOVER_DATA, payload: data })
    dispatch(getProducts(user))
  }
}

function setDiscoverDataForPaginationCall(data) {
  return {
    type: SET_DISCOVER_DATA_PAGINATION,
    payload: data,
  }
}

function setData(data) {
  return (dispatch) => {
    dispatch({ type: SET_PHOTO_DATA, payload: data })
    dispatch({ type: SET_LOADING, payload: false })
  }
}

export const clearAlbum = () => ({
  type: CLEAR_ALBUM,
})

function getQueryString(params) {
  let queryString = Object.keys(params)
    .map(function (key) {
      return key + "=" + params[key]
    })
    .join("&")
  return queryString
}
