import { Component } from "react"
import CryptoJS from "crypto-js"
import { API, NOOP } from "../actions/types"
import { SERVER_URL_DEV, SERVER_URL_PROD, SERVER_URL_TEST } from "../config"
export default class Api extends Component {
  static returnApiUrl() {
    //54321
    // return SERVER_URL_PROD
    if (env.dev) {
      return SERVER_URL_DEV
    }
    if (env.test) {
      return SERVER_URL_TEST
    }
    if (env.prod) {
      return SERVER_URL_PROD
    }
    // return SERVER_URL_TEST
  }

  static setAuthorizationToken(login, multipart) {
    let header = new Headers({
      "content-type": "application/json",
    })
    if (multipart) {
      header = new Headers()
    }
    if (!login) {
      const user = JSON.parse(localStorage.getItem("user"))
      header.append("WOO_ACCESS_TOKEN", user["accessToken"])
      header.append("TIME_OF_CALL", user.timeOfCall)
    }
    console.log("header", header)
    return header
  }

  static apiAction({
    url = "",
    method = "GET",
    multipart = false,
    data = null,
    onSuccess = () => {
      return { type: NOOP }
    },
    label = "",
    login = false,
    statusCodeCallBack = () => {},
    callback = () => {},
    failureCallback = () => {},
    handleLargeNumber = false,
  }) {
    return {
      type: API,
      payload: {
        url,
        method,
        multipart,
        data,
        onSuccess,
        label,
        login,
        callback,
        statusCodeCallBack,
        failureCallback,
        handleLargeNumber,
      },
    }
  }

  static apiCall(url, params, method, login, multipart) {
    const host = this.returnApiUrl()
    let fullUrl = `${host}${url}`
    console.log("full url", fullUrl)
    let header = this.setAuthorizationToken(login, multipart)
    let callForParams = params
    if (!multipart) {
      callForParams = params
        ? typeof params == "string"
          ? params
          : JSON.stringify(params)
        : null
    }
    return fetch(fullUrl, {
      method: method,
      headers: header,
      body: callForParams,
    })
  }

  static encryptToken(token, key) {
    const cryptkey = CryptoJS.enc.Utf8.parse(key)
    const encrypted = CryptoJS.AES.encrypt(token, cryptkey, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    })
    return encrypted.toString()
  }
}
