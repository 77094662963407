import * as firebase from "firebase/app";
import "firebase/messaging";
const initializedFirebaseApp = firebase.initializeApp({
	// Project Settings => Add Firebase to your web app
   apiKey: "AIzaSyCNZJRjER3bPghYxJ5rPEPR1IVmjMe61ho",
   authDomain: "woo-app-8bc4e.firebaseapp.com",
   databaseURL: "https://woo-app-8bc4e.firebaseio.com",
   projectId: "woo-app-8bc4e",
   storageBucket: "woo-app-8bc4e.appspot.com",
   messagingSenderId: "893929712036",
   appId: "1:893929712036:web:f863e3bd62618422c9f931"
});
let messaging= undefined
try{
messaging = initializedFirebaseApp.messaging();
messaging.usePublicVapidKey(
  // Project Settings => Cloud Messaging => Web Push certificates
  "BCCvBybRWWLKxcRUUnbIi_a9NbaYW6AbcH0rpVdmRopgsY-QnVtieVRDEsV3swFJkJFJyLlJyQ-gqKN3-tY-zgo"
);
}catch(err){

}

/*messaging.onMessage((payload) => {
  console.log('Message received. ', payload);
  // ...
  navigator.serviceWorker.p
});*/

export { messaging };