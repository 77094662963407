import React from 'react';
import { Route, Redirect } from 'react-router-dom';

class PrivateRoute extends React.Component{
    render(){
        const {component:Component, ...rest} = this.props;
        const {search} = rest.location
        const user = localStorage.getItem('user')
        const userObj = user ? JSON.parse(user) : null
        const isUserLoggedIn = userObj && userObj['loginDto']
        let isUserConfirmed = userObj && userObj["confirmed"]
        let pathName = "/home"
        if (isUserConfirmed){
            pathName = "/discoverScreen"
        }
        console.log("isUserConfirmed and pathName",isUserConfirmed,isUserLoggedIn,pathName);
        console.log("pvr", this.props.location);
        return(
            <Route {...rest} render={props => (
                (isUserLoggedIn && !isUserConfirmed) ?
                    <Component {...props} user={userObj}/> 
                    : <Redirect to={{ pathname: pathName, state: { from: props.location}, search}} />
            )} />
        )
    }
}

export {PrivateRoute};
