const styles = theme => ({
  round:{
    borderRadius:30,
  },
  spinner:{
    color:'#fff'
  },
  shadows: {
    boxShadow: 'none',
  },
  redBackgroundColor: {
    backgroundColor: theme.palette.primary.medium + ' !important',
    '& span': {
      color: theme.palette.common.white,
    }
  },
  fullWidth: {
    width: '100%',
  },
  fixedBottom: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    borderRadius: 0,
  },
  circular: {
    borderRadius: '50%',
  }
});

export default styles
