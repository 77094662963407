import {
  SERVER_URL_DEV,
  SERVER_URL_PROD,
  SERVER_URL_TEST,
  CHANNEL_ID,
  THEME,
  MID,
  INDUSTRY_TYPE_ID,
  WEBSITE,
  callBackUrl,
  SUBS_AMOUNT_TYPE,
  SUBS_ENABLE_RETRY,
  SUBS_RETRY_COUNT,
  SUBS_EXPIRY_DATE,
  SUBS_FREQUENCY_UNIT,
  SUBS_PPI_ONLY,
} from "../config"

export function getPaytmPayload(user, wooProduct, purchaseScreenType) {
  const ORDER_ID =
    wooProduct["planId"] + "-x-" + Math.round(new Date().getTime() / 1000)
  var txnAmount = "1"
  //54321
  // if(env.prod){
  // 	txnAmount = wooProduct['price'].toString()
  // }
  //wooProduct['validityTime']
  let paytmPayload = {}
  if (purchaseScreenType === "WOOPLUS") {
    paytmPayload = {
      CUST_ID: user["wooId"],
      CHANNEL_ID: CHANNEL_ID,
      ORDER_ID: ORDER_ID,
      TXN_AMOUNT: txnAmount,
      CALLBACK_URL: returnApiUrl() + callBackUrl,
      THEME: THEME,
      MID: MID,
      REQUEST_TYPE: "SUBSCRIBE",
      INDUSTRY_TYPE_ID: INDUSTRY_TYPE_ID,
      WEBSITE: WEBSITE,
      SUBS_AMOUNT_TYPE: SUBS_AMOUNT_TYPE,
      SUBS_ENABLE_RETRY: SUBS_ENABLE_RETRY,
      SUBS_RETRY_COUNT: SUBS_RETRY_COUNT,
      SUBS_EXPIRY_DATE: SUBS_EXPIRY_DATE,
      SUBS_FREQUENCY: wooProduct["validityTime"],
      SUBS_FREQUENCY_UNIT: SUBS_FREQUENCY_UNIT,
      SUBS_PPI_ONLY: SUBS_PPI_ONLY,
      SUBS_MAX_AMOUNT: txnAmount,
      SUBS_SERVICE_ID: ORDER_ID,
    }
  } else {
    paytmPayload = {
      CUST_ID: user["wooId"],
      CHANNEL_ID: CHANNEL_ID,
      ORDER_ID: ORDER_ID,
      TXN_AMOUNT: txnAmount,
      CALLBACK_URL: returnApiUrl() + callBackUrl,
      THEME: THEME,
      MID: MID,
      REQUEST_TYPE: "DEFAULT",
      INDUSTRY_TYPE_ID: INDUSTRY_TYPE_ID,
      WEBSITE: WEBSITE,
    }
  }

  return paytmPayload
}

export function getPaytmTransactionPayload(checksumData, purchaseScreenType) {
  let transactionAmount = "1"
  //54321
  // if (env.prod) {
  //   transactionAmount = checksumData["TXN_AMOUNT"];
  // }
  console.log("checksumData", checksumData)
  let payTmRequestParams = {}
  if (purchaseScreenType === "WOOPLUS") {
    payTmRequestParams = {
      CALLBACK_URL: returnApiUrl() + callBackUrl,
      CHANNEL_ID: checksumData["CHANNEL_ID"],
      CHECKSUMHASH: checksumData["CHECKSUMHASH"],
      CUST_ID: checksumData["CUST_ID"],
      INDUSTRY_TYPE_ID: checksumData["INDUSTRY_TYPE_ID"],
      MID: checksumData["MID"],
      ORDER_ID: checksumData["ORDER_ID"],
      REQUEST_TYPE: checksumData["REQUEST_TYPE"],
      TXN_AMOUNT: transactionAmount,
      WEBSITE: checksumData["WEBSITE"],
      THEME: checksumData["THEME"],
      SUBS_AMOUNT_TYPE: checksumData["SUBS_AMOUNT_TYPE"],
      SUBS_ENABLE_RETRY: checksumData["SUBS_ENABLE_RETRY"],
      SUBS_RETRY_COUNT: checksumData["SUBS_RETRY_COUNT"],
      SUBS_EXPIRY_DATE: checksumData["SUBS_EXPIRY_DATE"],
      SUBS_FREQUENCY: checksumData["SUBS_FREQUENCY"],
      SUBS_FREQUENCY_UNIT: checksumData["SUBS_FREQUENCY_UNIT"],
      SUBS_PPI_ONLY: checksumData["SUBS_PPI_ONLY"],
      SUBS_MAX_AMOUNT: transactionAmount,
      SUBS_SERVICE_ID: checksumData["SUBS_SERVICE_ID"],
    }
  } else {
    payTmRequestParams = {
      CUST_ID: checksumData["CUST_ID"],
      CHANNEL_ID: checksumData["CHANNEL_ID"],
      CHECKSUMHASH: checksumData["CHECKSUMHASH"],
      ORDER_ID: checksumData["ORDER_ID"],
      TXN_AMOUNT: transactionAmount,
      CALLBACK_URL: returnApiUrl() + callBackUrl,
      THEME: checksumData["THEME"],
      MID: checksumData["MID"],
      REQUEST_TYPE: checksumData["REQUEST_TYPE"],
      INDUSTRY_TYPE_ID: checksumData["INDUSTRY_TYPE_ID"],
      WEBSITE: checksumData["WEBSITE"],
    }
  }

  return payTmRequestParams
}

function returnApiUrl() {
  //54321
  // return SERVER_URL_PROD
  if (env.dev) {
    return SERVER_URL_DEV
  }
  if (env.test) {
    return SERVER_URL_TEST
  }
  if (env.prod) {
    return SERVER_URL_PROD
  }
}
