import { height } from "@material-ui/system";

const skin = localStorage.getItem("skin")
console.log("skin",skin)
let skinTheme = null
if(skin){
  console.log("skin-if",skin)
  skinTheme = JSON.parse(skin)
console.log("skinTheme-if", skinTheme )
}
console.log("skinTheme", skinTheme )


const styles = (theme) =>({
    root:{
      flexGrow:1,
      // backgroundColor : skinTheme ? skinTheme.sidebarBgColor : '#ffd21a',
      boxShadow: '0 -4px 8px 0 rgba(92, 97, 111, 0.78)',
    },
    icon:{
      marginRight : '10px;'
    },
    dialogContent:{
      padding: '0px !important',
      height: '100%',
      width:'100%'
    }	,
    label: {
      textTransform: 'capitalize',
      color:"#d3a900"
    },
    paymentPaperDialog:{
      display:'flex',
      alignItems:'flex-end'
    },
    fullHeightDialog: {
      margin: 0,
      maxHeight: '100%',
    },
    rootTab: {
      '& .icon': {
        margin: '0 !important',
      }
    }
  })
  
  export default styles;
  