import React from "react";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import withRoot from "../../withRoot";
import { history, Translate } from "../../helpers";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import { connect } from "react-redux";
import { DOMAIN } from "../../config";
import {
  loginUser,
  getDetailsOfTrueCallerUser,
  getCSRFToken,
} from "../../actions/login_actions";
import {
  BrowserView,
  MobileView,
  CustomView,
  isAndroid,
  isIOS,
  isBrowser,
  isSafari,
} from "react-device-detect";
import {
  onClickAndroidBadge,
  onClickIOSBadge,
  onClickInstallButton,
  privacyUrl,
} from "../../config";
import { LoadingButton } from "../../components/Button/LoadingButton";
// import { trackEventForUser } from "../../helpers/MixpanelTracker";
import { getCookie } from "../../helpers/cookie";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { enLanguage, frLanguage } from "../../langData";

// import 'react-toastify/dist/ReactToastify.css';

// import smartlookClient from 'smartlook-client'

import loadable from "@loadable/component";
import { Link } from "react-router-dom";
import Language from "../Language/Language";

const OtherComponent = loadable(() =>
  import("../../components/Button/ExportLoadingButton")
);

var buttonNameEnum = {
  EXPLORE: 1,
  ALREADY_HAVE_PROFILE: 2,
};
class HomePage extends React.Component {
  constructor(props) {
    super(props);
    // const user = localStorage.getItem("user");
    // let userObj;
    // let stateVar = 0;
    // if (user) {
    //   userObj = JSON.parse(user);
    //   stateVar =
    //     userObj["gender"] == "MALE" ? 0 : userObj["gender"] == "FEMALE" ? 1 : 0;
    //   userObj = {
    //     ...userObj,
    //     gender: stateVar == 0 ? "MALE" : stateVar == 1 ? "FEMALE" : "UNKNOWN",
    //   };
    //   localStorage.setItem("user", JSON.stringify(userObj));
    // } else {
    //   userObj = {
    //     ...userObj,
    //     gender: stateVar == 0 ? "MALE" : stateVar == 1 ? "FEMALE" : "UNKNOWN",
    //   };
    //   localStorage.setItem("user", JSON.stringify(userObj));
    // }
    this.state = {
      // selectedGender: stateVar,
      code: "+91",
      display: "modal",
      accountKitInitiated: false,
      loginDisabled: false,
      lang: "",
      appSkin: null,
    };

    this.loginCallback = this.loginCallback.bind(this);
  }

  componentDidMount() {
    // smartlook
    // if (!localStorage.getItem('utm')) {
    //   window.location.reload();
    // }
    // smartlookClient.init('1cf6e095495bdee3afc982df0668befb00e3325f');

    const user = localStorage.getItem("user");
    this.trueCallerCallNumber = 0;

    if (this.requestId == null) {
      this.props.getCSRFToken();
    }

    //trackEventForUser("LOGIN_SCREEN_LANDING");

    if (!user) {
      console.log("location", this.props.location);
      if (this.props.location) {
        const { search } = this.props.location;
        console.log("searrch value", search);
        if (!search || search == "") {
          localStorage.setItem("utm", this.setUtmParamsValueAndReturn(null));
        } else {
          localStorage.setItem("utm", search);
        }
      } else {
        localStorage.setItem("utm", this.setUtmParamsValueAndReturn(null));
      }
    } else {
      localStorage.setItem("utm", this.setUtmParamsValueAndReturn(null));
    }
    const utm = localStorage.getItem("utm");
    console.log("utm values organic", utm);

    console.log("isSafari", isSafari);
    if (isSafari) {
      this.setState({ display: null });
    } else {
      this.setState({ display: "modal" });
    }

    const userObj = user ? JSON.parse(user) : null;
    let isUserConfirmed = userObj && userObj["confirmed"];
    console.log("isUserConfirmed", isUserConfirmed);
    if (isUserConfirmed) {
      history.replace("/discoverScreen");
    }
    this.defaultLangugageSelected();

    setTimeout(() => {
      const skin = localStorage.getItem("skin");
      console.log("skin", skin);
      let skinTheme = null;
      if (skin) {
        console.log("skin-if", skin);
        skinTheme = JSON.parse(skin);
        console.log("skinTheme-if", skinTheme);
        this.setState({
          appSkin: skinTheme,
        });
      }
      console.log("skinTheme", skinTheme);
      if (skinTheme != null) {
        localStorage.setItem("skinLoader", skinTheme.theme.favicon);
        //console
      }
    }, 500);
  }

  defaultLangugageSelected = () => {
    let selectedLanguge;
    if (localStorage.getItem("ukuLangCode")) {
      if (localStorage.getItem("ukuLangCode") === "en") {
        selectedLanguge = "English";
      } else {
        selectedLanguge = "French";
      }
    } else {
      const languageId = getCookie("X-Reycreo-Language-Id");
      if (languageId === "en") {
        selectedLanguge = "English";
      } else {
        selectedLanguge = "French";
      }
    }
    this.setState({
      lang: selectedLanguge,
    });
  };

  langHandleChange = (event) => {
    this.setState({ lang: event.target.value });
    const { lang } = this.state;
    setTimeout(() => {
      console.log("lang", lang);
      if (confirm(`${Translate("SureChangeLanguage")}?`)) {
        localStorage.setItem("ukuLangCode", lang === "English" ? "fr" : "en");
        localStorage.setItem(
          "ukuLang",
          lang === "English"
            ? JSON.stringify(frLanguage)
            : JSON.stringify(enLanguage)
        );
        window.location.reload();
        // const user = localStorage.getItem("user");
        // let userObj = user ? JSON.parse(user) : null;
        // this.props.logout(userObj);
      }
    }, 500);
  };

  setUtmParamsValueAndReturn(searchString) {
    let utmParams;
    let utmParamsString;
    console.log("searchString", searchString);
    if (!searchString || searchString == "") {
      utmParamsString =
        "?" +
        "utm_source=ORGANIC" +
        "&utm_medium=ORGANIC" +
        "&utm_term=ORGANIC" +
        "&utm_content=ORGANIC" +
        "&utm_campaign=ORGANIC";
      return utmParamsString;
    } else {
      var stringSeparatedByAnd = searchString.split("&");
      if (stringSeparatedByAnd.length > 0) {
        var value;
        for (value of stringSeparatedByAnd) {
          console.log("value", value);
          console.log("includes", value.includes("utm_source"));
          if (value.includes("utm_source")) {
            var stringSeparatedByEqualToSource = value.split("=");
            console.log(
              "stringSeparatedByEqualToSource",
              stringSeparatedByEqualToSource
            );
            if (stringSeparatedByEqualToSource.length == 2) {
              utmParams = {
                ...utmParams,
                utm_source: stringSeparatedByEqualToSource[1],
              };
            }
          }

          if (value.includes("utm_medium")) {
            var stringSeparatedByEqualToMedium = value.split("=");
            console.log(
              "stringSeparatedByEqualToMedium",
              stringSeparatedByEqualToMedium
            );
            if (stringSeparatedByEqualToMedium.length == 2) {
              utmParams = {
                ...utmParams,
                utm_medium: stringSeparatedByEqualToMedium[1],
              };
            }
          }

          if (value.includes("utm_term")) {
            var stringSeparatedByEqualToTerm = value.split("=");
            console.log(
              "stringSeparatedByEqualToTerm",
              stringSeparatedByEqualToTerm
            );
            if (stringSeparatedByEqualToTerm.length == 2) {
              utmParams = {
                ...utmParams,
                utm_term: stringSeparatedByEqualToTerm[1],
              };
            }
          }

          if (value.includes("utm_content")) {
            var stringSeparatedByEqualToContent = value.split("=");
            console.log(
              "stringSeparatedByEqualToSource",
              stringSeparatedByEqualToContent
            );
            if (stringSeparatedByEqualToContent.length == 2) {
              utmParams = {
                ...utmParams,
                utm_content: stringSeparatedByEqualToContent[1],
              };
            }
          }

          if (value.includes("utm_campaign")) {
            var stringSeparatedByEqualToCampaign = value.split("=");
            console.log(
              "stringSeparatedByEqualToCampaign",
              stringSeparatedByEqualToCampaign
            );
            if (stringSeparatedByEqualToCampaign.length == 2) {
              utmParams = {
                ...utmParams,
                utm_campaign: stringSeparatedByEqualToCampaign[1],
              };
            }
          }
        }
      }
    }
    console.log("utmPArama", utmParams);
    return utmParams;
  }

  makePollingRequestForTrueCallerDetails() {
    //window.alert("makePollingRequestForTrueCallerDetails started")
    console.log(
      "pollingRequestTimer=",
      this.pollingRequestTimer,
      this.trueCallerCallNumber,
      this.requestId
    );
    //window.alert(this.trueCallerCallNumber)
    if (this.trueCallerCallNumber < 5) {
      //window.alert("makePollingRequestForTrueCallerDetails call is made")
      this.trueCallerCallNumber = this.trueCallerCallNumber + 1;
      this.props.getDetailsOfTrueCallerUser(this.requestId);
    } else {
      clearInterval(this.pollingRequestTimer);
      this.setState({ loginDisabled: false });
    }
  }

  makeLoginCallWithTrueCaller() {
    const trueCallerDto = {
      requestId: this.requestId,
    };

    let utmParams;
    if (this.props.location) {
      const { search } = this.props.location;
      utmParams = this.setUtmParamsValueAndReturn(search);
    } else {
      utmParams = this.setUtmParamsValueAndReturn(null);
    }
    //window.alert(this.requestId)
    this.props.loginUser(
      null,
      this.requestId,
      trueCallerDto,
      // this.state.selectedGender,
      utmParams
    );
  }

  loginCallback(response) {
    console.log("response from facebook>>>", response);
    if (response.status === "PARTIALLY_AUTHENTICATED") {
      const auth_code = response.code;
      const csrf = response.state;
      console.log("csrf", csrf);
      if (csrf === "qwerty") {
        let utmParams;
        if (this.props.location) {
          const { search } = this.props.location;
          utmParams = this.setUtmParamsValueAndReturn(search);
        } else {
          utmParams = this.setUtmParamsValueAndReturn(null);
        }
        console.log("utmParams login callback", utmParams);
        this.props.loginUser(
          auth_code,
          csrf,
          null,
          // this.state.selectedGender,
          utmParams
        );
      } else {
        console.log("call modified");
      }
    } else if (response.status === "NOT_AUTHENTICATED") {
      console.log("Authentication Failure");
    } else if (response.status === "BAD_PARAMS") {
      console.log("Parameter error");
    }
  }

  initiatePhoneNumberLoginFlow() {
    let utmParams;
    if (this.props.location) {
      const { search } = this.props.location;
      utmParams = this.setUtmParamsValueAndReturn(search);
    } else {
      utmParams = this.setUtmParamsValueAndReturn(null);
    }
    //trackEventForUser("FirebasePhoneLoginInitiated");

    history.push({
      pathname: "/phoneVerify",
      state: {
        utmParams: utmParams,
        // gender: "gender"
      },
    });

    /*
    const {code, display,accountKitInitiated} = this.state;
    console.log("display, accountKitInitiated",display,accountKitInitiated)
    if (accountKitInitiated == false){
    window.AccountKit.init(
      {
        display: display,
        appId:"1439999919568107",
        state:"qwerty",
        version:"v1.2",
        fbAppEventsEnabled:true,
        debug:true
      }
    )
    this.setState({accountKitInitiated:true})
    }
    window.AccountKit && window.AccountKit.login(
            'PHONE',
            {countryCode: code},
            this.loginCallback
        );
    */
  }

  luvingTaleLogin = () => {
    this.props.loginUser(null);
  };

  initiateAccountKit = (buttonName) => {
    let countryId = getCookie("X-Reycreo-Country-Id");
    let authToken = getCookie("X-Reycreo-Auth-Token");
    let id = getCookie("X-Reycreo-Id");
    let languageId = getCookie("X-Reycreo-Language-Id");
    let operatorId = getCookie("X-Reycreo-Operator-Id");

    //this.props.loginUser("Login Started")
    if (buttonName == buttonNameEnum.EXPLORE) {
      //trackEventForUser("EXPLORE_PROFILES_CTA_TAPPED");
    } else {
      //trackEventForUser("ALREADY_HAVE_PROFILES_CTA_TAPPED");
    }
    this.setState({ loginDisabled: true });

    if (typeof this.requestId !== "undefined") {
      //this.requestId= Math.floor(Math.random()*1000000000) + 100000000 + Math.round((new Date()).getTime() / 1000)
    }

    if (isIOS || isSafari) {
      //trackEventForUser("NATIVE_PHONENUMBER_INITIATED");
      console.log("loginTest1");
      if (countryId && authToken && id && languageId && operatorId) {
        this.luvingTaleLogin();
      } else {
        this.luvingTaleLogin();
      }
    } else {
      //54321
      let app_key = "JUZxZ6c592d0e622d4b9a81de046bf1b5f606";

      // if(env.dev){
      //   app_key = "spn758bfbf90378f54af0a001b3f65cf25151"
      // }
      // if(env.test){
      //   app_key = "JUZxZ6c592d0e622d4b9a81de046bf1b5f606"
      // }
      // if(env.prod){
      //   app_key = "ubYbHa8c04607bd6848ba8fd5e447f4448973"
      // }

      // window.location = "intent://truesdk/web_verify?requestNonce=" + this.requestId +
      //   "&partnerKey=" + app_key + "&partnerName=Woo&lang=English&title=Default&skipOption=skip#Intent;scheme=truecallersdk;end";

      setTimeout(() => {
        if (document.hasFocus()) {
          //trackEventForUser("TRUECALLER_INITIATED");
          console.log("loginTest2");
          if (countryId && authToken && id && languageId && operatorId) {
            this.luvingTaleLogin();
          } else {
            this.luvingTaleLogin();
          }
        } else {
          if (this.trueCallerCallNumber > 0) {
            this.trueCallerCallNumber = 0;
          }
          setTimeout(() => {
            const pollingRequestTimer = setInterval(() => {
              this.makePollingRequestForTrueCallerDetails();
            }, 5000);
            this.pollingRequestTimer = pollingRequestTimer;
          }, 2000);
        }
      }, 600);
    }
  };
  // handleChange = (gender) => {
  //   //trackEventForUser("LOGIN_GENDER_SELECTED");
  //   let user = JSON.parse(localStorage.getItem("user"));
  //   user = {
  //     ...user,
  //     gender: gender == 0 ? "MALE" : gender == 1 ? "FEMALE" : "UNKNOWN",
  //   };
  //   console.log(user);
  //   localStorage.setItem("user", JSON.stringify(user));
  //   this.setState({ selectedGender: gender });
  // };

  homepageUnsubscribeHandler = () => {
    const unsubUrl = `http://${DOMAIN}/billing/unsubscribe`;
    window.location.replace(unsubUrl);
  };

  downloadWooApp = (headerClicked, buttonType) => {
    let eventName = "";
    if (headerClicked) {
      onClickInstallButton();
      if (isIOS) {
        eventName = "LOGIN_DOWNLOAD_IOS_APP";
      } else {
        eventName = "LOGIN_DOWNLOAD_ANDROID_APP";
      }
    } else {
      if (buttonType == "iOS") {
        onClickIOSBadge();
        eventName = "LOGIN_DOWNLOAD_IOS_APP";
      } else {
        onClickAndroidBadge();
        eventName = "LOGIN_DOWNLOAD_ANDROID_APP";
      }
    }
    console.log("eventName from login", eventName);
    //trackEventForUser(eventName);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.csrfToken !== this.props.csrfToken) {
      console.log("nextProps", nextProps);
      // window.alert(nextProps.csrfToken)
      if (nextProps.csrfToken != null) {
        this.requestId = nextProps.csrfToken;
        console.log("this.requestId", this.requestId);
      }
    }

    if (nextProps.trueCallerDataFound !== this.props.trueCallerDataFound) {
      //window.alert(nextProps.trueCallerDataFound)
      if (nextProps.trueCallerDataFound == true) {
        this.makeLoginCallWithTrueCaller();
        clearInterval(this.pollingRequestTimer);
      } else if (nextProps.trueCallerDataFound == false) {
        //window.alert("getCSRFToken call is made")
        //this.props.getCSRFToken()
        clearInterval(this.pollingRequestTimer);
        this.initiatePhoneNumberLoginFlow();
      }
    }
  }

  render() {
    const { classes, isLoadingData } = this.props;
    const { selectedGender, loginDisabled, appSkin } = this.state;

    // const maleImage =
    //   selectedGender == 0 ? "images/male.svg" : "images/male_unselect.svg";
    // const femaleImage =
    //   selectedGender == 1 ? "images/female.svg" : "images/female_unselect.svg";
    let headerView = null;
    console.log("isBrowser", isBrowser);
    console.log("location", this.props.location, this.props);
    let loadingButtonClassName = "explore-button";
    //window.alert(isLoadingData)
    if (isLoadingData) {
      loadingButtonClassName = "explore-button-emptyText";
    }

    // if (isBrowser == false) {
    //   headerView = <div className="header-login">
    //     <img className='header-logo' width="50px" height="50px" src="images/logo.png"></img>
    //     <div className='header-login-title-container'>
    //       <p className='header-login-title'>Woo - <br /> Dating App Women Love</p>
    //     </div>
    //     <div className="header-button-grp">
    //       <Button className="button-get" onClick={(() => this.downloadWooApp(true, "iOS"))}>GET</Button>
    //       <Button className="button-open" onClick={(() => this.downloadWooApp(true, "iOS"))}>OPEN</Button>
    //     </div>
    //   </div>
    // }

    let countryId = getCookie("X-Reycreo-Country-Id");
    let authToken = getCookie("X-Reycreo-Auth-Token");
    let id = getCookie("X-Reycreo-Id");
    let languageId = getCookie("X-Reycreo-Language-Id");
    let operatorId = getCookie("X-Reycreo-Operator-Id");

    console.log("appskin", appSkin);

    return (
      <div>
        {headerView}
        {appSkin && (
          <div
            className="bg"
            style={{
              height: `100vh`,
              background: `url(${appSkin.homePageBgImgUrl})`,
            }}
          >
            <div className="m-web-container remove-min-height">
              <Card
                style={{
                  boxShadow: "none",
                  maxHeight: "fit-content",
                  height: "max-content",
                  flex: "1",
                  marginLeft: "36px",
                  marginRight: "36px",
                  background: "transparent",
                  margin: "0",
                }}
              >
                <div
                  className="login-card"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100vh",
                  }}
                >
                  <div className={`left login-card-header`}>
                    {/* <img className='login-card-header-img' src="images/group-2.png"></img> */}
                    <img
                      className="login-card-header-img"
                      // src={`images/${countryId}_${operatorId}_${languageId}_logo.png`}
                      src={
                        appSkin.logoUrl
                          ? appSkin.logoUrl
                          : `images/${countryId}_${operatorId}_${languageId}_logo.png`
                      }
                      // src={`images/ukuthanda-logo.png`}
                    ></img>
                    {appSkin.supportedLanguages.length > 1 && (
                      <FormControl className={classes.formControl}>
                        <Select
                          value={this.state.lang}
                          onChange={this.langHandleChange}
                          displayEmpty
                          className={classes.selectEmpty}
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          {appSkin.supportedLanguages.map((lang) => (
                            <MenuItem value={lang.languageName}>
                              {lang.languageName}
                            </MenuItem>
                          ))}
                          {/* <MenuItem value="English">English</MenuItem>
                      <MenuItem value="French">French</MenuItem> */}
                        </Select>
                      </FormControl>
                    )}
                    {window.location.hostname.includes("manakyow") && (
                      <div
                        style={{
                          backgroundColor: "rgba(255,255,255,0.13)",
                          display: "block",
                          width: "auto",
                          height: "34px",
                          borderRadius: "17px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "7px 14px",
                        }}
                      >
                        <button onClick={this.homepageUnsubscribeHandler}>
                          {Translate("Unsubscribe")}
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="login-card-body">
                    <p
                      style={{
                        textAlign: "center",
                        textShadow: "0 1px 0 rgb(0 0 0 / 50%)",
                        color: appSkin.primaryAccentColor
                          ? appSkin.primaryAccentColor
                          : "#ff0",
                        fontSize: "26px",
                        textTransform: "uppercase",
                        fontFamily: "'Poppins', sans-serif",
                        fontWeight: 700,
                        letterSpacing: "1px",
                        lineHeight: "30px",
                        marginBottom: "10px",
                      }}
                    >
                      {`${Translate("MakeMeaningfulConnections")}!`}
                    </p>
                    {/* <p className="select-gender">
                    {Translate("SelectYourGender")}
                  </p>
                  <div className={`gender-box text-center`}>
                    <div
                      className="gender-box-item"
                      onClick={() => this.handleChange(0)}
                    >
                      <img src={maleImage} className="male"></img>
                      <p
                        style={{ color: selectedGender == 0 ? "#827de1" : "" }}
                        className="i-m-gender"
                      >{`${Translate("IAm")} ${Translate("AMan")}`}</p>
                    </div>
                    <div
                      className="gender-box-item"
                      onClick={() => this.handleChange(1)}
                    >
                      <img src={femaleImage} className="male"></img>
                      <p
                        style={{ color: selectedGender == 1 ? "#827de1" : "" }}
                        className="i-m-gender"
                      >{`${Translate("IAm")} ${Translate("AWoman")}`}</p>
                    </div>
                  </div> */}
                    <OtherComponent
                      disabled={loginDisabled}
                      isLogin={true}
                      loading={isLoadingData}
                      onClick={() =>
                        this.initiateAccountKit(buttonNameEnum.EXPLORE)
                      }
                      className={loadingButtonClassName}
                      color="primary"
                      disableRipple
                      style={{
                        backgroundColor: appSkin.primaryColor,
                        color: appSkin.primaryAccentColor,
                      }}
                    >
                      {Translate("JoinUkuthanda")}
                    </OtherComponent>
                    {/* <p
                    onClick={() =>
                      this.initiateAccountKit(
                        buttonNameEnum.ALREADY_HAVE_PROFILE
                      )
                    }
                    style={{ textAlign: "center" }}
                    className="already-profile-text"
                  >
                    {Translate("AlreadyHaveProfile")}
                  </p> */}

                    <div
                      style={{
                        backgroundColor: "rgba(255,255,255,0.13)",
                        display: "block",
                        width: "auto",
                        height: "34px",
                        borderRadius: "17px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "7px 14px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Lato",
                          fontWeight: 700,
                          color: appSkin.primaryAccentColor
                            ? appSkin.primaryAccentColor
                            : "#ff0",
                          fontSize: "17px",
                        }}
                      >
                        {Translate("From18YearsOld")}
                      </p>
                    </div>
                    <p
                      style={{
                        fontSize: ".8em",
                        textAlign: "center",
                        marginRight: "16px",
                        marginBottom: "16px",
                        marginLeft: "16px",
                        color: "rgba(255,255,255,0.7)",
                        fontFamily: "LATO",
                        fontSize: "14px",
                      }}
                    >
                      {Translate("ByLoggingIn")} <br />
                      <Link
                        style={{
                          color: appSkin.primaryColor
                            ? appSkin.primaryColor
                            : "#428630",
                          marginRight: "5px",
                        }}
                        to={
                          localStorage.getItem("ukuLangCode") === "en"
                            ? `/${countryId}_${operatorId}_${languageId}_privacyPolicy`
                            : `/${countryId}_${operatorId}_${languageId}_privacyPolicy`
                        }
                      >
                        {Translate("Terms")}
                      </Link>
                      {Translate("And")}
                      <Link
                        style={{
                          color: appSkin.primaryColor
                            ? appSkin.primaryColor
                            : "#428630",
                          marginLeft: "5px",
                        }}
                        to={
                          localStorage.getItem("ukuLangCode") === "en"
                            ? `/${countryId}_${operatorId}_${languageId}_privacyPolicy`
                            : `/${countryId}_${operatorId}_${languageId}_privacyPolicy`
                        }
                      >
                        {Translate("PrivacyPolicy")}
                      </Link>
                    </p>

                    {/* <span style={{ textAlign: 'center', marginRight: '16px', marginBottom: '16px', marginLeft: '16px' }} >OR</span> */}
                  </div>
                  {/* <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "0 20px 15px 15px",
                    cursor: "pointer",
                  }}
                  onClick={this.languageClickHandler}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    style={{ width: "17px", height: "17px", color: "#482683" }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"
                    />
                  </svg>
                  <span
                    style={{
                      fontWeight: 600,
                      color: "#636262",
                      paddingLeft: "5px",
                      fontSize: "14px",
                    }}
                  >
                    {selectedLanguge}
                  </span>
                </div> */}
                  {/* <div className='login-card-footer'>
                  <BrowserView>
                    <img className='google-play-badge' src="images/gplay.svg" onClick={(() => this.downloadWooApp(false, "Android"))}></img>
                    <img className='app-store-badge' src="images/app-store.svg" onClick={(() => this.downloadWooApp(false, "iOS"))}></img>
                  </BrowserView>
                  <MobileView>
                    <CustomView condition={isAndroid}>
                      <img className='google-play-badge' src="images/gplay.svg" onClick={(() => this.downloadWooApp(false, "Android"))}></img>
                    </CustomView>
                    <CustomView condition={isIOS}>
                      <img className='app-store-badge' src="images/app-store.svg" onClick={(() => this.downloadWooApp(false, "iOS"))}></img>
                    </CustomView>

                  </MobileView>


                </div> */}
                </div>
              </Card>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ local, remote }) => {
  const { gender } = local;
  const { isLoadingData, login, trueCallerDataFound, csrfToken } = remote;
  return {
    login,
    trueCallerDataFound,
    csrfToken,
    gender,
    isLoadingData,
  };
};
const styledComponent = withRoot(withStyles(styles)(HomePage));
const connectedComponent = connect(mapStateToProps, {
  loginUser,
  getDetailsOfTrueCallerUser,
  getCSRFToken,
})(styledComponent);
// export default connectedComponent;
export { connectedComponent as HomePage };
