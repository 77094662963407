import Api from "../helpers/api"
import {
  SET_LOGIN_DATA,
  SET_TRUECALLER_DATA,
  DO_LOGIN,
  SET_LOGINDONE_DATA,
  DO_LOGINDONE,
  TRUECALLER_DETAILS_REQUEST,
  GET_CSRF_TOKEN,
  SET_CSRF_TOKEN,
  GENERATE_OTP,
  SET_ACCESS_TOKEN_BEFORE_VERIFICATION,
  VERIFY_PHONE_NUMBER,
  SET_ACCESS_TOKEN_AFTER_VERIFICATION,
} from "./types"
import { history } from "../helpers/history"
import { APP_VERSION, DEVICE_TYPE, TELCO_LOGIN_FAIL_URL } from "../config"
//import { trackEventForUser } from "../helpers/MixpanelTracker"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { getCookie } from "../helpers/cookie"

let OTPScreenDetails = {}

export function getCSRFToken() {
  return Api.apiAction({
    url: `/v1/generate/csrf`,
    onSuccess: setCsrfData,
    label: GET_CSRF_TOKEN,
    method: "GET",
    login: true,
    callback: csrfTokenCallback,
    failureCallback: csrfTokenFailureCallback,
  })
}

function setCsrfData(data) {
  return {
    type: SET_CSRF_TOKEN,
    payload: data,
  }
}

function csrfTokenFailureCallback(status) {}

function csrfTokenCallback(data) {}

let countryId = getCookie("X-Reycreo-Country-Id")
let authToken = getCookie("X-Reycreo-Auth-Token")
let id = getCookie("X-Reycreo-Id")
let languageId = getCookie("X-Reycreo-Language-Id")
let operatorId = getCookie("X-Reycreo-Operator-Id")

export function loginUser(
  phoneVerifyToken,
  csrfToken,
  trueCallerDetails,
  gender,
  utmParams,
  tokenTyp = ""
) {
  console.log("utmParams login", utmParams)
  const genderStr = gender == 0 ? "MALE" : gender == 1 ? "FEMALE" : null
  const deviceId = getOrGenerateDeviceId()
  console.log("deviceId", deviceId)
  let utmSource = "MWeb"
  let utmMedium = "UNKNOWN"
  let utmTerm = "MWeb"
  let utmContent = "UNKNOWN"
  let utmCampaign = "UNKNOWN"
  if (utmParams) {
    if (utmParams["utm_source"]) {
      utmSource = utmParams["utm_source"]
    }

    if (utmParams["utm_medium"]) {
      utmMedium = utmParams["utm_medium"]
    }

    if (utmParams["utm_term"]) {
      utmTerm = utmParams["utm_term"]
    }

    if (utmParams["utm_content"]) {
      utmContent = utmParams["utm_content"]
    }

    if (utmParams["utm_campaign"]) {
      utmCampaign = utmParams["utm_campaign"]
    }
  }
  let authorizationData = {}
  /*
    authorizationData = {
        authType: "FACEBOOK_ACCOUNT_KIT",
        facebookAccountKitDto: {
            accessToken: accountKitToken,
            csrfToken: csrfToken
        }
    }
    */

  if (phoneVerifyToken != null) {
    tokenTyp == "fb"
      ? (authorizationData = {
          authType: "FIREBASE_LOGIN",
          firebaseLoginDto: {
            idToken: phoneVerifyToken,
          },
        })
      : (authorizationData = {
          authType: "NATIVE_OTP",
          nativeOtpDto: {
            accessToken: phoneVerifyToken,
          },
        })
  } else if (trueCallerDetails != null) {
    authorizationData = {
      authType: "TRUECALLER_WEB",
      truecallerWebDto: trueCallerDetails,
    }
  }

  let loginData = {
    age: 0,
    appVersion: APP_VERSION,
    authDto: authorizationData,
    deviceId: deviceId,
    deviceType: DEVICE_TYPE,
    gmtTime: "19800",
    language: "English",
    latitude: 0.0,
    locale: "en",
    longitude: 0.0,
    utmSource: utmSource,
    utmMedium: utmMedium,
    utmTerm: utmTerm,
    utmContent: utmContent,
    utmCampaign: utmCampaign,
    gender: genderStr,
  }

  if (countryId && authToken && id && languageId && operatorId) {
    const msisdn = "+" + id.substr(6)
    const countryCode = id.substr(6, 2)
    let userLangCode = languageId
    if (localStorage.getItem("ukuLangCode")) {
      userLangCode = localStorage.getItem("ukuLangCode")
    }
    console.log("userLangCode", userLangCode)
    loginData = {
      // age: 0,
      appVersion: APP_VERSION,
      language: userLangCode,
      authDto: {
        authType: "U2WEB",
        u2WebDto: {
          accessToken: authToken,
          msisdn: msisdn,
          countryCode: countryCode,
          u2WebId: id,
        },
        // u2WebDto: {
        //     accessToken: "c484fb1d-1af4-4c8d-b74b-bce0cd90fe2e",
        //     msisdn: "+918010078100",
        //     countryCode: "91",
        //     u2WebId: "WCE_PH918010078100"
        // }
      },
      deviceId: deviceId,
      deviceType: DEVICE_TYPE,
      crossDomain: false,
    }
  }
  console.log("loginData", loginData)
  if (gender == -1) {
    loginData.gender
  }
  return Api.apiAction({
    url: `/v3/user/activity/login`,
    data: loginData,
    onSuccess: setLoginData,
    label: DO_LOGIN,
    method: "POST",
    login: true,
    callback: loginCallback,
    failureCallback: registerationFailureCallback,
  })
}

function loginCallback(data) {
  const token = data["wooToken"]
  const time = Date.now() + "000"
  const accessToken = Api.encryptToken(token, time)
  const photoUploaded = data["profilePicUrl"] != null
  const userJson = localStorage.getItem("user")
  let locationValue = "Gurgaon"
  if (data.hasOwnProperty("city")) {
    locationValue = data["city"]
  }
  let user
  if (userJson) {
    user = JSON.parse(userJson)
    user = {
      ...user,
      accessToken,
      timeOfCall: time,
      wooId: data["id"],
      confirmed: data["confirmed"],
      registered: data["isUserRegistered"],
      photoUploaded: photoUploaded,
      loginDto: data,
      selectedLocation: locationValue,
    }
  } else {
    user = {
      ...user,
      accessToken,
      timeOfCall: time,
      wooId: data["id"],
      confirmed: data["confirmed"],
      gender: data["gender"],
      registered: data["isUserRegistered"],
      photoUploaded: photoUploaded,
      loginDto: data,
      selectedLocation: locationValue,
    }
  }
  localStorage.setItem("user", JSON.stringify(user))
  //trackEventForUser("SUCCESSFULL_LOGIN")
  fbq("track", "Successfull_Login_MWeb")
  const localUser = localStorage.getItem("user")
  const userObj = localUser ? JSON.parse(localUser) : null
  console.log("userObj[confirmed]", userObj["confirmed"])
  if (userObj["confirmed"] == false) {
    gtag("event", "Successfull_Login_MWeb")
    gtag("event", "conversion", {
      send_to: "AW-755754629/uIFdCJue37UBEIXNr-gC",
    })
  }

  setTimeout(() => {
    history.push("/")
  }, 2000)
}

function setLoginData(data) {
  return {
    type: SET_LOGIN_DATA,
    payload: data,
  }
}

export function sendOTPOnNumber(phoneNumber) {
  console.log("phoneNumber", phoneNumber)
  const payload = {
    phoneNumber: phoneNumber,
    countryCode: "91",
  }
  return Api.apiAction({
    url: `/v4/foneverify/generateOtp`,
    data: payload,
    login: true,
    onSuccess: setAccessTokenBeforeVerification,
    label: GENERATE_OTP,
    failureCallback: otpFailureCallback,
    method: "POST",
  })
}

function otpFailureCallback(status) {
  console.log("otpFailureCallback", status)
  if (status === 429) {
    toast("Too many requests from this number!")
    history.goBack()
  } else if (status === 406) {
    toast("Please generate OTP after sometime!")
  }
}

function setAccessTokenBeforeVerification(data) {
  return {
    type: SET_ACCESS_TOKEN_BEFORE_VERIFICATION,
    payload: data,
  }
}

export function verifyOTP(otp, accessToken, otpDetails) {
  OTPScreenDetails = otpDetails
  const payload = {
    accessToken: accessToken,
    otp: otp,
  }
  return Api.apiAction({
    url: `/v4/foneverify/verify/otp`,
    data: payload,
    login: true,
    onSuccess: setAccessTokenAfterVerification,
    label: VERIFY_PHONE_NUMBER,
    failureCallback: verifyOtpFailureCallback,
    method: "POST",
  })
}

function verifyOtpFailureCallback(status) {
  console.log("otpFailureCallback", status, OTPScreenDetails)
  if (status === 403) {
    toast("OTP Expired! Please generate a new one.")
    //history.goBack()
  } else if (status === 401) {
    toast("Please enter correct OTP!")
    history.replace({ pathname: "/OTP", state: OTPScreenDetails })
  }
}

function setAccessTokenAfterVerification(data) {
  return {
    type: SET_ACCESS_TOKEN_AFTER_VERIFICATION,
    payload: data,
  }
}

export function getDetailsOfTrueCallerUser(requestNonce) {
  const payload = {
    requestId: requestNonce,
  }
  return Api.apiAction({
    url: `/v1/truecaller/polling/callbackData?requestId=${requestNonce}`,
    data: payload,
    login: true,
    onSuccess: setTrueCallerData,
    label: TRUECALLER_DETAILS_REQUEST,
    method: "POST",
    callback: trueCallerCallback,
    failureCallback: trueCallerFailureCallback,
  })
}

function setTrueCallerData(data) {
  return {
    type: SET_TRUECALLER_DATA,
    payload: data,
  }
}

function trueCallerFailureCallback(status) {
  console.log("status in truecaller", status)
  if (status == 204) {
    //no data found
  }
}

function trueCallerCallback(data) {
  console.log("data in truecaller", data)
}

export function registerUser(user, nameScreen, dobScreen, gender) {
  const dob = Date.UTC(dobScreen.year, dobScreen.month - 1, dobScreen.day)
  const name = nameScreen["fullName"].split(" ")
  const payload = {
    wooId: user["wooId"],
    firstName: name[0],
    lastName: (name.length > 1 && name[1]) || "",
    gender: gender == 0 ? "MALE" : gender == 1 ? "FEMALE" : "UNKNOWN",
    dob: dob,
    latitude: 0.0,
    longitude: 0.0,
  }
  return Api.apiAction({
    url: `/v1/user/activity/register?wooId=${user["wooId"]}`,
    data: payload,
    onSuccess: setLoginData,
    label: DO_LOGIN,
    method: "POST",
    callback: registerCallback,
    failureCallback: registerationFailureCallback,
  })
}

export function registerUserWithoutGender(user, nameScreen, dobScreen) {
  const dob = Date.UTC(dobScreen.year, dobScreen.month - 1, dobScreen.day)
  const name = nameScreen["fullName"].split(" ")
  const payload = {
    wooId: user["wooId"],
    firstName: name[0],
    lastName: (name.length > 1 && name[1]) || "",
    gender: user["gender"],
    dob: dob,
    latitude: 0.0,
    longitude: 0.0,
  }
  return Api.apiAction({
    url: `/v1/user/activity/register?wooId=${user["wooId"]}`,
    data: payload,
    onSuccess: setLoginData,
    label: DO_LOGIN,
    method: "POST",
    callback: registerCallback,
    failureCallback: registerationFailureCallback,
  })
}

function registerationFailureCallback(status) {
  if (status != 200) {
    console.log("login action login url", TELCO_LOGIN_FAIL_URL)
    // window.location = TELCO_LOGIN_FAIL_URL
    window.location.replace(TELCO_LOGIN_FAIL_URL)
  }

  //     }
  //     else {
  //     if (status == 416) {
  //         let user = JSON.parse(localStorage.getItem('user'));
  //         user = {
  //             ...user,
  //             underAgeUser: true,
  //         }
  //         localStorage.setItem('user', JSON.stringify(user));
  //     }
  //     //trackEventForUser("USER_REGISTERATION_FAILED")
  //     history.push("/")
  // }
}

function registerCallback(data) {
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    gender: data["gender"],
    registered: data["isUserRegistered"],
    loginDto: data,
  }
  localStorage.setItem("user", JSON.stringify(user))
  //trackEventForUser("USER_REGISTERED_SUCCESSFULLY")
  history.push("/")
}

export function callLoginDone(user) {
  const payload = {
    wooId: user["wooId"],
  }
  return Api.apiAction({
    url: `/v3/user/activity/login/done?wooId=${user["wooId"]}`,
    data: payload,
    onSuccess: setLoginDoneData,
    label: DO_LOGIN,
    method: "POST",
    callback: loginDoneCallBack,
  })
}

function loginDoneCallBack(data) {
  console.log("data for loginCallDone", data)
  let user = JSON.parse(localStorage.getItem("user"))
  user = {
    ...user,
    confirmed: true,
  }
  //window.gtag("event", "Onboarding_complete_mweb")
  //trackEventForUser("Onboarding_complete")
  //ga('send', 'event', 'Onboarding_complete_MWeb');
  gtag("event", "Onboarding_complete_MWeb")
  //firebase.analytics().logEvent('Onboarding_complete_mweb', { wooID: user['wooId']});
  localStorage.setItem("user", JSON.stringify(user))
  history.push("/")
}

function setLoginDoneData(data) {
  return {
    type: SET_LOGINDONE_DATA,
    payload: data,
  }
}

function getOrGenerateDeviceId() {
  //let deviceId = localStorage.getItem('deviceId')
  //if(!deviceId){
  const deviceId = "WEB_" + generateDeviceId()
  localStorage.setItem("deviceId", deviceId)
  //}
  return deviceId
}
function generateDeviceId() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1)
  }
  return s4() + s4() + s4()
}
