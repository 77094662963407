export const API = "API";
export const API_START = "API_START";
export const API_END = "API_END";
export const ACCESS_DENIED = "ACCESS_DENIED";
export const API_ERROR = "API_ERROR";

export const GENDER_SELECT = "GENDER_SELECT"

export const SET_LOGIN_DATA = "SET_LOGIN_DATA";
export const DO_LOGIN ="DO_LOGIN";

export const PHOTO_UPLOAD = "PHOTO_UPLOAD"
export const SET_PHOTO_DATA = "SET_PHOTO_DATA"

export const PHOTO_UPLOAD_CHAT = "PHOTO_UPLOAD_CHAT"
export const SET_CHAT_PHOTO_DATA = "SET_CHAT_PHOTO_DATA"

export const CLEAR_ALBUM = "CLEAR_ALBUM"

export const SET_LOGINDONE_DATA = "SET_LOGINDONE_DATA"
export const DO_LOGINDONE = "DO_LOGINDONE"
export const SET_DISCOVER_DATA = "SET_DISCOVER_DATA"
export const SET_DISCOVER_DATA_PAGINATION = "SET_DISCOVER_DATA_PAGINATION"
export const DO_DISCOVER ="DO_DISCOVER"
export const NOOP="NOOP"
export const SET_VISITOR_DATA = "SET_VISITOR_DATA"
export const GET_VISITOR = "GET_VISITOR"

export const SET_LIKEDME_DATA = "SET_LIKEDME_DATA"
export const GET_LIKEDME = "GET_LIKEDME"

export const SET_SKIPPED_DATA = "SET_SKIPPED_DATA"
export const GET_SKIPPED_DATA = "GET_SKIPPED_DATA"

export const SET_LIKEDBYME_DATA = "SET_LIKEDBYME_DATA"
export const GET_LIKEDBYME_DATA = "GET_LIKEDBYME_DATA"

export const SET_CRUSHES_DATA = "SET_CRUSHES_DATA"
export const GET_CRUSHES_DATA = "GET_CRUSHES_DATA"

export const LIKE_USER = "LIKE_USER"
export const DISLIKE_USER = "DISLIKE_USER"

export const SET_TRUECALLER_DATA = "SET_TRUECALLER_DATA"
export const TRUECALLER_DETAILS_REQUEST = "TRUECALLER_DETAILS_REQUEST"

export const GET_CSRF_TOKEN = "GET_CSRF_TOKEN"
export const SET_CSRF_TOKEN = "SET_CSRF_TOKEN"

export const GENERATE_OTP = "GENERATE_OTP"
export const SET_ACCESS_TOKEN_BEFORE_VERIFICATION = "SET_ACCESS_TOKEN_BEFORE_VERIFICATION"

export const VERIFY_PHONE_NUMBER = "VERIFY_PHONE_NUMBER"
export const SET_ACCESS_TOKEN_AFTER_VERIFICATION = "SET_ACCESS_TOKEN_AFTER_VERIFICATION"

export const CHECKSUM_GENERATED = "CHECKSUM_GENERATED"

export const GET_USER_PROFILE = "GET_USER_PROFILE"
export const USER_PROFILE = "USER_PROFILE"

export const SET_LOADING = "SET_LOADING"

export const GET_MATCHES = "GET_MATCHES"

