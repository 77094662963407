import {
    SET_LOADING
  } from "../actions/types";
  
export default function(state = {}, action) {
    console.log("action type => ", action.type);
    switch (action.type) {
        case SET_LOADING:
            return { 
                ...state,
                isLoading: action.payload,
            };
        default:
            return state;
    }
}
  