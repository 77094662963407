import React, {Suspense, lazy} from "react";
import { withStyles } from "@material-ui/core/styles";
import styles from "./style";
import withRoot from "../../withRoot";
// import { NameScreen } from "../NameScreen/NameScreen";
import { DiscoverScreen } from "../DiscoverScreen/DiscoverScreen";
// import { UploadPhotoScreen } from "../UploadPhotoScreen/UploadPhotoScreen";
// import { LocationPermissionScreen } from "../LocationPermissionScreen/LocationPermissionScreen";
// import { BlockerScreen } from "../BlockerScreen/BlockerScreen";
// import { AboutMe } from "../AboutMe/AboutMe";

// import loadable from '@loadable/component';

const NameScreen = lazy(() => import('../NameScreen'));
const AboutMe = lazy(() => import('../AboutMe'));
// const DiscoverScreen = lazy(() => import('../DiscoverScreen'));
const LocationPermissionScreen = lazy(() => import('../LocationPermissionScreen'));
const UploadPhotoScreen = lazy(() => import('../UploadPhotoScreen'));
const BlockerScreen = lazy(() => import('../BlockerScreen'));


class UserHome extends React.Component {
  constructor(props) {
    super(props);
  }

  getComponent = () => {
    const { user } = this.props;
    console.log("userHome user", user)
    console.log("props",this.props)
    let userTagsSelected;
    userTagsSelected = this.props.location.state && this.props.location.state.userTagsSelected ? this.props.location.state.userTagsSelected : undefined;
    console.log("userTagsSelected",userTagsSelected ? userTagsSelected : "no user tags")
    if (user.underAgeUser) {
      return <Suspense fallback={<div>Loading...</div>}><BlockerScreen user={user} /></Suspense>;
    }
    if (!user.registered) {
      return <Suspense fallback={<div>Loading...</div>}><NameScreen user={user} /></Suspense>;
    }
    if (
      user.registered &&
      !userTagsSelected
    ) {
      return <Suspense fallback={<div>Loading...</div>}><AboutMe user={user} /></Suspense>;
    }
    if (
      user.registered &&
      userTagsSelected &&
      !user.confirmed &&
      !user.photoUploaded
    ) {
      return <Suspense fallback={<div>Loading...</div>}><UploadPhotoScreen user={user} /></Suspense>;
    }
    

    if (
      user.registered &&
      userTagsSelected &&
      !user.confirmed &&
      user.photoUploaded
    ) {
      return <Suspense fallback={<div>Loading...</div>}><LocationPermissionScreen user={user}></LocationPermissionScreen></Suspense>;
    }
    return <Suspense fallback={<div>Loading...</div>}><DiscoverScreen user={user}></DiscoverScreen></Suspense>;
  };
  render() {
    return <React.Fragment>{this.getComponent()}</React.Fragment>;
  }
}

const styledComponent = withRoot(withStyles(styles)(UserHome));
export { styledComponent as UserHome };
