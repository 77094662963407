import red from '@material-ui/core/colors/red';
const styles = theme => ({
  card: {
    flex:1,
    width: 250,
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },

  genericCard :{
    flex:1,
    width: 350,
    '& a':{
      textDecoration:'none',
    }
  },

  discoverCard:{
    flex:1,
    width: 200,
    '& a':{
      textDecoration:'none',
    }
  }
});

export default styles
